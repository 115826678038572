import * as React from 'react';
import {
    Button,
    Card,
    CardActionArea,
    CardContent,
    Checkbox,
    createStyles,
    FormControl,
    Grid,
    InputBase,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Theme,
    Typography,
    withStyles
} from "@material-ui/core";
import { WithStyles } from '@material-ui/styles';
import { Account, Folder } from '../../shared/domain';
import { Structure } from '../../models/structure';
import { Marker } from '../../models';
import { NodesTreeView, RenderTree } from '../../shared/components/node-tree';
import { PickedType } from '../../features/marker-export/marker-export';
import AccountProvider from '../../providers/account.provider';
import FolderProvider from '../../providers/folder.provider';
import _ from 'lodash';
import { SimpleListPagination } from '../../shared/components/simple-list-pagination';
import { SimpleListItem } from '../../shared/components/simple-list';
import FloorplanProvider from '../../providers/floorplan.provider';
import { CCSpinner } from '../../shared/components/cc-spinner';
import { Alert, AlertTitle, Autocomplete } from '@material-ui/lab';
import { RunningTasks } from '../../providers/job.provider';
import MarkerProvider from '../../providers/marker.provider';
import { ModuleGroup } from '../../models/module-group';
import { MarkerSearchCategory, MarkerSourceItem } from '../../features/marker-detail/marker-detail';

interface MarkerList {
    folder: string;
    floorplan: string;
    checked: boolean;
    marker: Marker;
}

enum WizardSteps {
    MarkerOrigin = 0,
    MarkerSet,
    AddModuleOperation,
    RemoveModuleOperation,
    Confirmation,
    Result
}

const WIZARD_STEPS = [
    {
        id: WizardSteps.MarkerOrigin,
        label: 'Marker origins'
    },
    {
        id: WizardSteps.MarkerSet,
        label: 'Select markers'
    },
    {
        id: WizardSteps.AddModuleOperation,
        label: 'Choose module group(s) to ADD'
    },
    {
        id: WizardSteps.RemoveModuleOperation,
        label: 'Choose module group(s) to DELETE'
    },
    {
        id: WizardSteps.Confirmation,
        label: 'Confirm changes'
    },
    {
        id: WizardSteps.Result,
        label: 'Results'
    }
];

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    transferButton: {
        padding: '0.5em 0',
        margin: '1em 0'
    },
    treePaper: {
        height: '90%',
        maxHeight: '35em',
        overflowY: 'scroll',
        [theme.breakpoints.down('lg')]: {
            maxHeight: '32em',
        }
    },
    formPaper: {
        maxHeight: '35em',
        padding: '1em'
    },
    treeContainer: {
        background: '#fff',
        padding: '1em',
        height: '100%'
    },
    heading: {
        fontWeight: 700,
        marginBottom: '0.5em',
        marginTop: '0.5em'
    },
    ellipsis: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    simpleList: {
        overflow: 'auto',
        height: '24em',
        width: '100%'
    },
    stepsContentHolder: {
        padding: '1em',
        height: '40em'
    },
    stepsHolder: {
        paddingBottom: 0
    },
    gridHolders: {
        height: '95%'
    },
    centerButton: {
        display: 'block',
        margin: 'auto'
    },
    nextButton: {
        width: '8em',
        position: 'absolute',
        right: '1em',
        bottom: '1em',
    },
    progressContainer: {
        flexGrow: 1
    },
    markerCard: {
        position: 'relative',
        borderRadius: 0
    },
    markerCardBody: {
        padding: '0.5em !important',
        "& h6": {
            fontSize: '1.3em'
        }
    },
    markerCheckbox: {
        position: 'absolute',
        right: '1em'
    },
    emptyMarkerHolder: {
        height: '35em',
        display: 'flex',
        alignItems: 'center',
        "& h1": {
            width: '100%',
            textAlign: 'center',
            color: 'gray'
        }
    },
    footNote: {
        float: 'right',
        marginTop: '0.5em',
        color: 'gray',
        fontWeight: 'bold'
    },
    textField: {
        padding: '1em',
        width: '100%',
        '& label': {
            fontWeight: 'bolder',
            padding: '1em 0 0 1em',

            fontSize: '1.4em',
            color: 'rgba(0, 0, 0, 0.8) !important'
        },
        '&::before': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            borderBottomStyle: 'solid'
        }
    },
    searchInputHolder: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '5px',
    },
    searchCategoryInput: {
        width: '40%'
    },
    searchInput: {
        borderBottom: '1px solid #009933',
        borderLeft: '1px solid #009933',
        padding: '7px',
        // width: '100%'
    },
    allMarkersButton: {
        width: 'auto',
        position: 'absolute',
        left: '1em',
        bottom: '1em',
    }
});


interface Props extends WithStyles<typeof styles> {
    className?: string;
    currentAccount: Account;
    currentStructure?: Structure;
    canDelete: boolean;
    canAdd: boolean;
    currentNodeTree: RenderTree;
    folders: Folder[];
    availableModuleGroups: ModuleGroup[];
    accountProvider: AccountProvider;
    folderProvider: FolderProvider;
    floorplanProvider: FloorplanProvider;
    markerProvider: MarkerProvider;
    processJobs?: (jobs: RunningTasks[], sourceList: MarkerSourceItem[]) => void;
}

interface States {
    sourceList: MarkerSourceItem[];
    allMarkers: MarkerList[];
    searchMarkers: MarkerList[];
    operationMarkers: MarkerList[];
    allOperationMarkers: MarkerList[];
    allOperationMarkersError: boolean;
    currentStage: WizardSteps;
    currentNodeTree: RenderTree;
    treeIsLoading: boolean;
    treePickedType: PickedType;
    treePickedId: string;
    treePickedName: string;
    folders: Folder[];
    processedFolders: string[];
    processedFoldersWMarkers: string[];
    allFolders: Folder[];
    isContentLoading: boolean;
    addModuleGroups: ModuleGroup[];
    deleteModuleGroups: ModuleGroup[];
    producedJobsList: RunningTasks[];
    processedMarkers: number;
    failedMarkers: number;
    allMarkersSearch: string;
    allMarkersSearchCategory: MarkerSearchCategory;
    selectedMarkersSearch: string;
    selectedMarkersSearchCategory: MarkerSearchCategory;
    treeScrollPosition: number;
    autoSelectModuleGroupAdd: ModuleGroup | null;
    selectModuleGroupAddKey: string;
    autoSelectModuleGroupDel: ModuleGroup | null;
    selectModuleGroupDelKey: string;
}


class MarkerModuleGroupsWizard extends React.Component<Props, States> {

    generateRandomKey = () => {
        const randomKey = Math.random().toString(36).substring(7);
        return randomKey;
    };

    state: States = {
        sourceList: [],
        allMarkers: [],
        searchMarkers: [],
        operationMarkers: [],
        allOperationMarkers: [],
        allOperationMarkersError: false,
        currentStage: WizardSteps.MarkerOrigin,
        currentNodeTree: {id: '', name:'', children:[], path: ''},
        treeIsLoading: false,
        treePickedType: PickedType.None,
        treePickedId: '',
        treePickedName: '',
        processedFolders: [],
        processedFoldersWMarkers: [],
        folders: [],
        allFolders: [],
        isContentLoading: false,
        addModuleGroups: [],
        deleteModuleGroups: [],
        producedJobsList: [],
        processedMarkers: 0,
        failedMarkers: 0,
        allMarkersSearch: '',
        allMarkersSearchCategory: 'MARKER_NAME',
        selectedMarkersSearch: '',
        selectedMarkersSearchCategory: 'MARKER_NAME',
        treeScrollPosition: 0,
        autoSelectModuleGroupAdd: null,
        selectModuleGroupAddKey: this.generateRandomKey(),
        autoSelectModuleGroupDel: null,
        selectModuleGroupDelKey: this.generateRandomKey()
    }

    componentDidMount() {
        this.setUpInitialTree();
        this.loadAllAccountFolder();
    };

    setUpInitialTree = () => {
        // COPY THE ALREADY NAVIGATED TREE FOR BETTER UX
        const { currentNodeTree, folders } = this.props;
        this.setState({currentNodeTree, folders});
    };

    loadAllAccountFolder = () => {
        const { accountProvider, currentAccount } = this.props;
        this.setState({treeIsLoading: true});

        accountProvider.getFoldersByAccountId(currentAccount.accountId).then(data => {
            this.setState({
                treeIsLoading: false,
                allFolders: data
            })
        });
    };

    deepTreeSearch = (treeData: RenderTree, searchId: string) => {
        let foundTree: RenderTree | undefined;
        let searchLevel: RenderTree[] | undefined = treeData.children;
        do {
            foundTree = searchLevel?.find((e: RenderTree) => e.id === searchId);
            let tempLevel: any = [];
            searchLevel?.forEach((element: RenderTree) => {
                tempLevel = tempLevel.concat(element.children);
            });
            searchLevel = tempLevel;
        } while(!foundTree);
        return foundTree;
    };

    updateChildrenOnPath = (treeData: RenderTree, nodeId: string, children: RenderTree[]) => {
        treeData.children!.forEach(element => {
            if (_.isEqual(element.id, nodeId)) {
                element.children = children;
            } else {
                this.updateChildrenOnPath(element, nodeId, children);
            }
        });
    };

    onNodeSelected = (treeData: RenderTree , nodeId: string, nodeLabel: string, updateCallback: (nodeIds: string[]) => void) => {
        const { currentAccount, accountProvider, folderProvider } = this.props;
        const { folders, processedFolders, sourceList, processedFoldersWMarkers } = this.state;
        if(currentAccount!.accountId === nodeId) {
            this.setState({
                treePickedType: PickedType.None,
                treePickedId: '',
                treePickedName: ''
            });
            return;
        }
        this.setState({treeIsLoading: true});
        // CHECK PICK TYPE
        const currentNodePos = this.deepTreeSearch(treeData, nodeId);
        const nodePickedType = folders.find(e => e.id === nodeId)
            ? !processedFoldersWMarkers.includes(nodeId)
                ? PickedType.None
                : PickedType.Folder
            : PickedType.FloorPlan;
        // NOT PROCESSED FOLDER
        if(nodePickedType === PickedType.None) {
            // DID WE ALREADY CHECK FOR CHILDREN?
            if (processedFolders.find(e => e === nodeId)) {
                    // FOLDER CHILDREN
                    // WE DONT DO FOLDERS FOR PDF
                    this.setState({
                        treePickedType: PickedType.None,
                        treePickedId: '',
                        treePickedName: '',
                        currentNodeTree: treeData,
                        treeIsLoading: false
                    });
                    return;
            }
            // EMPTY
            // CHECK FOR FOLDER CHILDREN FIRST
            const currentPath = currentNodePos.path.slice(0, -2);
            processedFolders.push(nodeId);
            return accountProvider.getChildrenFolderByFolderId(currentAccount!.accountId, nodeId)
                .then((results: Folder[]) => {
                    if (results.length > 0) {
                        const nodes: RenderTree[] = results.map(element => {
                            const newItem = {
                                id: element.id,
                                name: element.name,
                                children: [],
                                path: `${currentNodePos.path}${element.id}__`
                            }
                            return newItem as RenderTree;
                        });
                        this.updateChildrenOnPath(treeData, nodeId, nodes);
                        updateCallback([currentAccount!.accountId, ...currentPath.split('__'), ...nodes.map(e => e.id)]);
                        this.setState({
                            treePickedType: PickedType.None,
                            treePickedId: '',
                            treePickedName: '',
                            currentNodeTree: treeData,
                            treeIsLoading: false,
                            folders: folders.concat(results),
                            processedFolders
                        });
                        return;
                    } else {
                        return folderProvider.fetchFloorplans(nodeId)
                            .then((floorplans) => {
                                if (floorplans.length > 0) {
                                    const nodes: RenderTree[] = floorplans.map((element: RenderTree) => {
                                        const newItem = {...element} as RenderTree;
                                        newItem.children = [];
                                        newItem.path = `${currentNodePos.path}${element.id}__`;
                                        return newItem;
                                    });
                                    this.updateChildrenOnPath(treeData, nodeId, nodes);
                                    updateCallback([currentAccount!.accountId, ...currentPath.split('__'), ...nodes.map(e => e.id)]);
                                    // THIS FOLDER FOLDER HAS FLOORPLAN
                                    // IT IS NOT SELECTABLE
                                    this.setState({
                                        treePickedType: PickedType.None,
                                        treePickedId: '',
                                        treePickedName: '',
                                        currentNodeTree: treeData,
                                        treeIsLoading: false,
                                        processedFolders
                                    });
                                    return;
                                }
                                // NO FLOORPLANS FOUND
                                // CHECK IF IT HAS MARKERS
                                return folderProvider.fetchFolderMarkers(nodeId).then((folderMarkers) => {
                                    const { markers } = folderMarkers;

                                    if (markers.length <= 0) {
                                        // NO MARKERS FOUND
                                        // CAN'T DO ANYTHING
                                        this.setState({
                                            treePickedType: PickedType.None,
                                            treePickedId: '',
                                            treePickedName: '',
                                            currentNodeTree: treeData,
                                            treeIsLoading: false,
                                            processedFolders
                                        });
                                        return;
                                    }

                                    processedFoldersWMarkers.push(nodeId);
                                    if (!sourceList.find(e => e.id === nodeId)) {
                                        sourceList.push({id: nodeId, name: nodeLabel, type: 'Folder'});
                                    }
                                    this.setState({
                                        treePickedType: PickedType.Folder,
                                        treePickedId: nodeId,
                                        treePickedName: currentNodePos.name,
                                        treeIsLoading: false,
                                        sourceList
                                    });

                                    return;
                                });
                        });
                    }
            });
        }

        if (!sourceList.find(e => e.id === nodeId)) {
            const sourceType = nodePickedType === PickedType.FloorPlan ? 'Floorplan' : 'Folder';
            sourceList.push({id: nodeId, name: nodeLabel, type: sourceType});
        }
        this.setState({
            treePickedType: nodePickedType,
            treePickedId: nodeId,
            treePickedName: currentNodePos.name,
            treeIsLoading: false,
            sourceList
        });

        return;
    };

    onNodeToggled = (nodeIds: string[]) => {
        return;
    };

    deleteSourceList = (listItem: SimpleListItem) => {
        const { sourceList } = this.state;

        const delIndex = sourceList.findIndex(e => e.id === listItem.id);

        if (delIndex >= 0) {
            sourceList.splice(delIndex, 1);
            this.setState({sourceList});
        }

        return;
    };

    loadAllFloorplans = () => {
        const { currentAccount, accountProvider } = this.props;

        this.setState({
            treeIsLoading: true,
            isContentLoading: true,
            allOperationMarkersError: false
        });
        accountProvider.getAllMarkers(currentAccount.accountId).then(data => {
            const { markers } = data;
            const readyMarkers = markers.map(e => {
                return {...e, checked: false} as MarkerList
            });
            const sourceList:MarkerSourceItem[] = [];
            markers.forEach(m => {
                if (!sourceList.find(e => e.id === m.marker.folder_id)) {
                    sourceList.push({
                        id: m.marker.folder_id,
                        name: m.folder,
                        type: 'Folder'
                    });
                } else if (m.marker.floorplan_id && !sourceList.find(e => e.id === m.marker.floorplan_id)) {
                    sourceList.push({
                        id: m.marker.floorplan_id,
                        name: m.floorplan,
                        type: 'Floorplan'
                    });
                }
            });
            this.setState({
                sourceList,
                allMarkers: readyMarkers,
                isContentLoading: false,
                treeIsLoading: false,
                searchMarkers: readyMarkers,
                currentStage: WizardSteps.MarkerSet
            });
        }).catch(error => {
            console.error(error);
            this.setState({
                treeIsLoading: false,
                isContentLoading: false,
                allOperationMarkersError: true
            });
        });
    };

    checkNextStageButton = () => {
        const { currentStage } = this.state;
        let flag = false;

        switch (currentStage) {
            case WizardSteps.MarkerOrigin:
                const { sourceList } = this.state;
                flag = sourceList.length <= 0;
                break;
        
            case WizardSteps.MarkerSet:
                const { operationMarkers } = this.state;
                flag = operationMarkers.length <= 0;
                break;

            case WizardSteps.Result:
                const { isContentLoading } = this.state;
                flag = isContentLoading;
                break;
            default:
                break;
        }

        return flag;
    };

    goNextStageButton = () => {
        const { currentStage, sourceList } = this.state;

        switch (currentStage) {
            case WizardSteps.MarkerOrigin:
                if (sourceList.length <= 0) {
                    break;
                }
                
                this.setState({currentStage: WizardSteps.MarkerSet});
                this.loadSelectedMarkers();
                break;

            case WizardSteps.MarkerSet:
                this.setState({currentStage: WizardSteps.AddModuleOperation});
                break;

            case WizardSteps.AddModuleOperation:
                this.setState({currentStage: WizardSteps.RemoveModuleOperation});
                break;
            
            case WizardSteps.RemoveModuleOperation:
                this.setState({currentStage: WizardSteps.Confirmation});
                break;
            
            case WizardSteps.Confirmation:
                this.processModuleGroupChanges();
                this.setState({currentStage: WizardSteps.Result});
                break;
            
            case WizardSteps.Result:
                const { processJobs } = this.props;

                if (!processJobs) {
                    break;
                }

                const { producedJobsList } = this.state;
                processJobs(producedJobsList, sourceList);
                break;

            default:
                break;
        }

    };

    loadSelectedMarkers = () => {
        const { sourceList, allFolders } = this.state;
        const { floorplanProvider, folderProvider } = this.props;
        let elapsed = 0;
        let allMarkers: MarkerList[] = [];

        this.setState({isContentLoading: true});
        sourceList.forEach(source => {
            if (source.type === 'Floorplan') {
                floorplanProvider.fetchMarkers(source.id).then(data => {
                    allMarkers = allMarkers.concat(data.map((m: Marker) => {
                        const newItem = {
                            folder: allFolders.find(f => f.id === m.folder_id)?.name,
                            floorplan: sourceList.find(f => f.id === m.floorplan_id)?.name,
                            checked: false,
                            marker: m,
                        }
                        return newItem as MarkerList;
                    }));
                    elapsed += 1;
    
                    if (elapsed === sourceList.length) {
                        this.setState({
                            allMarkers,
                            isContentLoading: false,
                            searchMarkers: allMarkers
                        })
                    }
                });
                return;
            }
            folderProvider.fetchFolderMarkers(source.id).then(data => {
                const { markers } = data;
                allMarkers = allMarkers.concat(markers.map((m: Marker) => {
                    const newItem = {
                        folder: allFolders.find(f => f.id === m.folder_id)?.name,
                        floorplan: '-',
                        checked: false,
                        marker: m,
                    }
                    return newItem as MarkerList;
                }));
                elapsed += 1;

                if (elapsed === sourceList.length) {
                    this.setState({
                        allMarkers,
                        isContentLoading: false,
                        searchMarkers: allMarkers
                    })
                }
            })
        });
    };

    checkMarker = (list: number, marker: MarkerList, event?: React.ChangeEvent<HTMLInputElement>) => {
        if (list === 0) {
            const { allMarkers } = this.state;
            const markerIndex = allMarkers.findIndex(m => m.marker.marker_id === marker.marker.marker_id);
            allMarkers[markerIndex].checked = event ? event.target.checked : !allMarkers[markerIndex].checked;
            this.setState({allMarkers});
        } else {
            const { operationMarkers } = this.state;
            const markerIndex = operationMarkers.findIndex(m => m.marker.marker_id === marker.marker.marker_id);
            operationMarkers[markerIndex].checked = event ? event.target.checked : !operationMarkers[markerIndex].checked;
            this.setState({operationMarkers});
        }
    };

    handleAllSearch = () => {
        let { searchMarkers, operationMarkers } = this.state;
        operationMarkers = operationMarkers.concat(searchMarkers);
        searchMarkers = []
        this.setState({
            operationMarkers,
            searchMarkers,
            allOperationMarkers: operationMarkers
        });
    };

    handleAllOperations = () => {
        let { searchMarkers, operationMarkers } = this.state;
        searchMarkers = searchMarkers.concat(operationMarkers);
        operationMarkers = []
        this.setState({
            operationMarkers,
            searchMarkers,
            allOperationMarkers: operationMarkers
        });
    };

    handleCheckedRight = () => {
        let { searchMarkers, operationMarkers } = this.state;
        const selected = searchMarkers.filter(m => m.checked);
        operationMarkers = operationMarkers.concat(selected);
        searchMarkers = searchMarkers.filter(m => !m.checked);
        this.setState({
            operationMarkers,
            searchMarkers,
            allOperationMarkers: operationMarkers
        });
    };

    handleCheckedLeft = () => {
        let { searchMarkers, operationMarkers } = this.state;
        const selected = operationMarkers.filter(m => m.checked);
        searchMarkers = searchMarkers.concat(selected);
        operationMarkers = operationMarkers.filter(m => !m.checked);
        this.setState({
            operationMarkers,
            searchMarkers,
            allOperationMarkers: operationMarkers
        });
    };

    onModuleSearch = (e: React.ChangeEvent<{}>, value: ModuleGroup | null, stage: number) => {
        if (!value) {
            return;
        }

        this.onAddModule(value, stage);
    };

    onAddModule = (module: ModuleGroup, stage: number) => {
        if (stage === 1) {
            const { addModuleGroups } = this.state
            addModuleGroups.push(module);
    
            this.setState({addModuleGroups});
        } else {
            const { deleteModuleGroups } = this.state
            deleteModuleGroups.push(module);
    
            this.setState({deleteModuleGroups});
        }
    };

    onAddListDeleteModule = (listItem: SimpleListItem) => {
        const { addModuleGroups } = this.state;

        const delIndex = addModuleGroups.findIndex(e => e.module_group_id === listItem.id);

        if (delIndex >= 0) {
            addModuleGroups.splice(delIndex, 1);
            this.setState({addModuleGroups});
        }

        return;
    };

    onDeleteListDeleteModule = (listItem: SimpleListItem) => {
        const { deleteModuleGroups } = this.state;

        const delIndex = deleteModuleGroups.findIndex(e => e.module_group_id === listItem.id);

        if (delIndex >= 0) {
            deleteModuleGroups.splice(delIndex, 1);
            this.setState({deleteModuleGroups});
        }

        return;
    };

    processModuleGroupChanges = () => {
        const { markerProvider, currentAccount } = this.props;
        const { operationMarkers, addModuleGroups, deleteModuleGroups, producedJobsList } = this.state;
        let totalNeededUpdates = 0;
        let totalProccessed = 0;
        this.setState({isContentLoading: true, currentStage: WizardSteps.Result});
        operationMarkers.forEach(m => {
            let needsUpdate = false;
            addModuleGroups.forEach(mo => {
                if (!m.marker.module_groups[mo.module_group_id]) {
                    m.marker.module_groups[mo.module_group_id] = true;
                    needsUpdate = true;
                }
            });
            deleteModuleGroups.forEach(mo => {
                if (m.marker.module_groups[mo.module_group_id]) {
                    delete m.marker.module_groups[mo.module_group_id];
                    needsUpdate = true;
                }
            });

            if (!needsUpdate) {
                return;
            }

            totalNeededUpdates += 1;
            markerProvider.updateMarker(m.marker).then(data => {
                const { processedMarkers } = this.state;
                const { marker } = data;

                if (marker.jobs) {
                    const jobId = Object.keys(marker.jobs)[0];
                    const newTask: RunningTasks = {
                        account: currentAccount,
                        export_name: `Updating marker: ${marker.name}`,
                        export_id: marker.marker_id,
                        type: 0,
                        task_id: jobId,
                        status: 102,
                        startedAt: Date.now(),
                        elapsedTime: 0,
                        progress: 0,
                        timer: 1500,
                        static_progress_count: 0,
                        set_to_stop: false
                    };
                    producedJobsList.push(newTask);
                }
                this.setState({
                    processedMarkers: processedMarkers + 1,
                    producedJobsList
                })
            }).catch(error => {
                console.error(error);
                const { failedMarkers } = this.state;
                this.setState({
                    failedMarkers: failedMarkers + 1
                });
            }).finally(() => {
                totalProccessed += 1;
                if (totalProccessed === totalNeededUpdates) {
                    this.setState({
                        isContentLoading: false
                    });
                }
            });
        });
        if (totalNeededUpdates <= 0) {
            this.setState({
                isContentLoading: false
            });
        }
    };

    handleAllMarkerSearchCategory = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({allMarkersSearchCategory: event.target.value as MarkerSearchCategory});
    };

    handleSelectedMarkerSearchCategory = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({selectedMarkersSearchCategory: event.target.value as MarkerSearchCategory});
    };

    onAllMarkersSearch = (event: React.ChangeEvent<HTMLInputElement> & React.KeyboardEvent<HTMLInputElement>) => {
        const searchTxt = event.target.value.toLowerCase();
        this.setState({allMarkersSearch: event.target.value});

        const { allMarkersSearchCategory, allMarkers, operationMarkers } = this.state;

        const searchset = allMarkers.filter(e => operationMarkers.findIndex(j => j.marker.marker_id === e.marker.marker_id) <= -1);

        if (searchTxt === '' || searchTxt.trim() === '') {
            this.setState({searchMarkers: searchset});
            return;
        }

        switch (allMarkersSearchCategory) {
            case 'MARKER_NAME':
                    this.setState({
                        searchMarkers: searchset.filter(e => e.marker.name.toLowerCase().indexOf(searchTxt) > -1)
                    });
                break;
            case 'MARKER_ID':
                    this.setState({
                        searchMarkers: searchset.filter(e => e.marker.marker_id.toLowerCase().indexOf(searchTxt) > -1)
                    });
                break;
            case 'FOLDER_NAME':
                    this.setState({
                        searchMarkers: searchset.filter(e => e.folder.toLowerCase().indexOf(searchTxt) > -1)
                    });
                break;
            case 'FLOORPLAN_NAME':
                    this.setState({
                        searchMarkers: searchset.filter(e => e.floorplan.toLowerCase().indexOf(searchTxt) > -1)
                    });
                break;
        
            default:
                break;
        }
    };

    onSelectedMarkersSearch = (event: React.ChangeEvent<HTMLInputElement> & React.KeyboardEvent<HTMLInputElement>) => {
        const searchTxt = event.target.value.toLowerCase();
        this.setState({selectedMarkersSearch: event.target.value});

        const { selectedMarkersSearchCategory, allOperationMarkers } = this.state;

        const searchset = allOperationMarkers;

        if (searchTxt === '' || searchTxt.trim() === '') {
            this.setState({operationMarkers: searchset});
            return;
        }

        switch (selectedMarkersSearchCategory) {
            case 'MARKER_NAME':
                    this.setState({
                        operationMarkers: searchset.filter(e => e.marker.name.toLowerCase().indexOf(searchTxt) > -1)
                    });
                break;
            case 'MARKER_ID':
                    this.setState({
                        operationMarkers: searchset.filter(e => e.marker.marker_id.toLowerCase().indexOf(searchTxt) > -1)
                    });
                break;
            case 'FOLDER_NAME':
                    this.setState({
                        operationMarkers: searchset.filter(e => e.folder.toLowerCase().indexOf(searchTxt) > -1)
                    });
                break;
            case 'FLOORPLAN_NAME':
                    this.setState({
                        operationMarkers: searchset.filter(e => e.floorplan.toLowerCase().indexOf(searchTxt) > -1)
                    });
                break;
        
            default:
                break;
        }
    };

    centerScroll = (evt: React.UIEvent<HTMLElement>) => {
        this.setState({
            treeScrollPosition: evt.currentTarget.scrollTop
        })
    };

    getAvailableModuleGroups = (stage: number): ModuleGroup[] => {
        const { addModuleGroups, deleteModuleGroups } = this.state;
        const { availableModuleGroups } = this.props;
        let finalModulegroupsList: ModuleGroup[] = [];
        if (stage === 1) {
            finalModulegroupsList = availableModuleGroups.filter(e => !addModuleGroups.find(j => j.module_group_id === e.module_group_id))
        } else if (stage === 2) {
            finalModulegroupsList = availableModuleGroups.filter(e => !deleteModuleGroups.find(j => j.module_group_id === e.module_group_id))
        }
        return finalModulegroupsList;
    };

    clearAutocompleteSelector = (evt: React.FocusEvent<HTMLDivElement> | undefined, isAddSelector: boolean) => {
        if (isAddSelector) {
            this.setState({ 
                autoSelectModuleGroupAdd: null,
                selectModuleGroupAddKey: this.generateRandomKey()
            });
            return;
        }
        this.setState({ 
            autoSelectModuleGroupDel: null,
            selectModuleGroupDelKey: this.generateRandomKey()
        });
        return;
    };

    public render() {
        const {
            className,
            classes
        } = this.props;
        const {
            currentStage,
            searchMarkers,
            operationMarkers,
            currentNodeTree,
            treeIsLoading,
            sourceList,
            isContentLoading,
            addModuleGroups,
            deleteModuleGroups,
            failedMarkers,
            processedMarkers,
            allMarkersSearch,
            allMarkersSearchCategory,
            selectedMarkersSearch,
            selectedMarkersSearchCategory,
            allOperationMarkersError,
            treeScrollPosition,
            autoSelectModuleGroupAdd,
            selectModuleGroupAddKey,
            autoSelectModuleGroupDel,
            selectModuleGroupDelKey
        } = this.state;

        const flooplanListItems = sourceList ? sourceList.map((item) => {
            return new SimpleListItem(item.id, `${item.type}: ${item.name}`, item.id); 
        }) : [];

        const addModuleGroupsListItems = addModuleGroups ? addModuleGroups.map((item) => {
            return new SimpleListItem(item.module_group_id, item.name, item.module_group_id)
        }): [];

        const deleteModuleGroupsListItems = deleteModuleGroups ? deleteModuleGroups.map((item) => {
            return new SimpleListItem(item.module_group_id, item.name, item.module_group_id)
        }): [];

        return (
            <div className={`${classes.root} ${className}`} data-testid="marker-module-groups-wizard">
                <div className={`${classes.container}`}>
                    <Stepper activeStep={currentStage} className={classes.stepsHolder} alternativeLabel>
                        { WIZARD_STEPS.map((step) => (
                            <Step key={step.id}>
                                <StepLabel>{step.label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <div className={classes.stepsContentHolder}>
                        {
                            currentStage === WizardSteps.MarkerOrigin ? 
                            <React.Fragment>
                                <Grid container spacing={1} alignItems="flex-start" className={classes.gridHolders} data-testid="origin-step">
                                    <Grid item xs={8} className={classes.gridHolders}>
                                        <Typography
                                            variant='h6'
                                            className={`${classes.heading} ${classes.ellipsis}`}
                                        >
                                            Select floorplans
                                        </Typography>
                                        <Paper
                                            className={classes.treePaper}
                                            onScrollCapture={this.centerScroll}
                                        >
                                            <NodesTreeView
                                                treeData={currentNodeTree}
                                                isLoading={treeIsLoading}
                                                onNodeSelected={this.onNodeSelected}
                                                onNodeToggled={this.onNodeToggled}
                                                className={classes.treeContainer}
                                                loadingOverlayPosition={treeScrollPosition}
                                            />
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={4} data-testid="selected-origin-documents">
                                        <Typography
                                            variant='h6'
                                            className={`${classes.heading} ${classes.ellipsis}`}
                                        >
                                            Floorplans / Folders selected
                                        </Typography>
                                        {
                                            allOperationMarkersError &&
                                                <Alert severity="error">
                                                    <AlertTitle>Error</AlertTitle>
                                                    Unable to load all markers in this account
                                                </Alert>
                                        }
                                        <SimpleListPagination
                                            className={classes.simpleList}
                                            keepItemSelected={false}
                                            items={flooplanListItems}
                                            canDeleteListItem={true}
                                            onDeleteItemClick={this.deleteSourceList}
                                            rowsPerPage={10}
                                            totalItems={flooplanListItems.length}
                                            offset={0}
                                            noItemsLabel="Nothing selected"
                                        />
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                            : currentStage === WizardSteps.MarkerSet ? 
                            <div data-testid="marker-step">
                                <CCSpinner
                                    label="Loading Markers"
                                    className={classes.progressContainer}
                                    loading={isContentLoading}
                                    size={200}
                                >
                                    <Grid
                                        container
                                        spacing={2}
                                        justifyContent="center"
                                        alignItems="center"
                                        className={classes.root}
                                    >
                                        <Grid item xs={4} data-testid="total-marker-holder">
                                            <div className={classes.searchInputHolder}>
                                                <FormControl className={classes.searchCategoryInput}>
                                                    <InputLabel id="all-search-label">Select by: </InputLabel>
                                                    <Select
                                                        labelId="all-search-label"
                                                        id="all-search-category"
                                                        data-testid="all-search-category"
                                                        value={allMarkersSearchCategory}
                                                        onChange={this.handleAllMarkerSearchCategory}
                                                    >
                                                    <MenuItem value="MARKER_NAME">Marker Name</MenuItem>
                                                    <MenuItem value="MARKER_ID">Marker ID</MenuItem>
                                                    <MenuItem value="FOLDER_NAME">Folder Name</MenuItem>
                                                    <MenuItem value="FLOORPLAN_NAME">Floorplan Name</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <InputBase
                                                    className={classes.searchInput}
                                                    placeholder="Search markers"
                                                    value={allMarkersSearch}
                                                    onChange={this.onAllMarkersSearch}
                                                    data-testid="all-search-input"
                                                />
                                            </div>
                                            <Paper className={classes.treePaper}>
                                                {
                                                    searchMarkers.length <= 0 ?
                                                    <div className={classes.emptyMarkerHolder}>
                                                        <Typography variant="h6" component="h1">
                                                            No markers to select
                                                        </Typography>
                                                    </div>
                                                    :
                                                    searchMarkers.map(m => {
                                                        return (
                                                        <Card className={classes.markerCard}>
                                                            <CardActionArea onClick={(e) => this.checkMarker(0, m)}>
                                                                <CardContent className={classes.markerCardBody}>
                                                                    <Checkbox
                                                                        className={classes.markerCheckbox}
                                                                        checked={m.checked}
                                                                        onChange={(e) => this.checkMarker(0, m, e)}
                                                                    />
                                                                    <Typography variant="h6" component="h6">
                                                                        {m.marker.name}
                                                                    </Typography>
                                                                    <Typography color="textSecondary">
                                                                        {m.marker.marker_id}
                                                                    </Typography>
                                                                    <Typography variant="body2" component="p">
                                                                        {m.folder}
                                                                        <br />
                                                                        {m.floorplan}
                                                                    </Typography>
                                                                </CardContent>
                                                            </CardActionArea>
                                                        </Card>
                                                        );
                                                    })
                                                }
                                            </Paper>
                                            <div className={classes.footNote}>
                                                {searchMarkers.length} Total Markers
                                            </div>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Grid container direction="column" alignItems="center">
                                            <Button
                                                variant="contained"
                                                size="small"
                                                color="secondary"
                                                className={classes.transferButton}
                                                onClick={this.handleAllSearch}
                                                disabled={searchMarkers.length === 0}
                                                aria-label="move all right"
                                                data-testid="move-all-right"
                                            >
                                                ≫
                                            </Button>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                color="secondary"
                                                className={classes.transferButton}
                                                onClick={this.handleCheckedRight}
                                                disabled={searchMarkers.filter(m => m.checked).length === 0}
                                                aria-label="move selected right"
                                                data-testid="move-selected-right"
                                            >
                                                &gt;
                                            </Button>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                color="secondary"
                                                className={classes.transferButton}
                                                onClick={this.handleCheckedLeft}
                                                disabled={operationMarkers.filter(m => m.checked).length === 0}
                                                aria-label="move selected left"
                                                data-testid="move-selected-left"
                                            >
                                                &lt;
                                            </Button>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                color="secondary"
                                                className={classes.transferButton}
                                                onClick={this.handleAllOperations}
                                                disabled={operationMarkers.length === 0}
                                                aria-label="move all left"
                                                data-testid="move-all-left"
                                            >
                                                ≪
                                            </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4} data-testid="selected-marker-holder">
                                            <div className={classes.searchInputHolder}>
                                                <FormControl className={classes.searchCategoryInput}>
                                                    <InputLabel id="selected-search-label">Select by: </InputLabel>
                                                    <Select
                                                        labelId="selected-search-label"
                                                        id="selected-search-category"
                                                        data-testid="selected-search-category"
                                                        value={selectedMarkersSearchCategory}
                                                        onChange={this.handleSelectedMarkerSearchCategory}
                                                    >
                                                    <MenuItem value="MARKER_NAME">Marker Name</MenuItem>
                                                    <MenuItem value="MARKER_ID">Marker ID</MenuItem>
                                                    <MenuItem value="FOLDER_NAME">Folder Name</MenuItem>
                                                    <MenuItem value="FLOORPLAN_NAME">Floorplan Name</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <InputBase
                                                    className={classes.searchInput}
                                                    placeholder="Search markers"
                                                    value={selectedMarkersSearch}
                                                    onChange={this.onSelectedMarkersSearch}
                                                    data-testid="selected-search-input"
                                                />
                                            </div>  
                                            <Paper className={classes.treePaper}>
                                                {
                                                    operationMarkers.length <= 0 ?
                                                    <div className={classes.emptyMarkerHolder}>
                                                        <Typography variant="h6" component="h1">
                                                            No markers selected
                                                        </Typography>
                                                    </div>
                                                    :
                                                    operationMarkers.map(m => {
                                                        return (
                                                        <Card className={classes.markerCard}>
                                                            <CardActionArea onClick={(e) => this.checkMarker(1, m)}>
                                                                <CardContent className={classes.markerCardBody}>
                                                                    <Checkbox
                                                                        className={classes.markerCheckbox}
                                                                        checked={m.checked}
                                                                        onChange={(e) => this.checkMarker(1, m, e)}
                                                                    />
                                                                    <Typography variant="h6" component="h6">
                                                                        {m.marker.name}
                                                                    </Typography>
                                                                    <Typography color="textSecondary">
                                                                        {m.marker.marker_id}
                                                                    </Typography>
                                                                    <Typography variant="body2" component="p">
                                                                        {m.folder}
                                                                        <br />
                                                                        {m.floorplan}
                                                                    </Typography>
                                                                </CardContent>
                                                            </CardActionArea>
                                                        </Card>
                                                        );
                                                    })
                                                }
                                            </Paper>
                                            <div className={classes.footNote}>
                                                {operationMarkers.length} Total Markers
                                            </div>
                                        </Grid>
                                    </Grid>
                                </CCSpinner>
                            </div>
                            : currentStage === WizardSteps.AddModuleOperation ? 
                            <React.Fragment>
                                <Grid container spacing={1} alignItems="flex-start" className={classes.gridHolders} data-testid="add-module-group-step">
                                    <Grid item xs={3} />
                                    <Grid item xs={6} className={classes.gridHolders}>
                                        <Typography
                                            variant='h6'
                                            className={`${classes.heading} ${classes.ellipsis}`}
                                        >
                                            Selected module group(s) to ADD
                                        </Typography>
                                        <Autocomplete
                                            id={`add-combo-box-module-group-selector`}
                                            key={selectModuleGroupAddKey}
                                            options={this.getAvailableModuleGroups(1)}
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => <TextField {...params} label="Select a module group" variant="standard" />}
                                            onChange={(e, pickedValue) => this.onModuleSearch(e, pickedValue, 1)}
                                            data-testid="add-module-group-form-input"
                                            value={autoSelectModuleGroupAdd}
                                            clearOnBlur={true}
                                            blurOnSelect={true}
                                            onBlur={(e) => this.clearAutocompleteSelector(e, true)}
                                        />
                                        <SimpleListPagination
                                            className={classes.simpleList}
                                            keepItemSelected={false}
                                            items={addModuleGroupsListItems}
                                            canDeleteListItem={true}
                                            onDeleteItemClick={this.onAddListDeleteModule}
                                            rowsPerPage={10}
                                            totalItems={addModuleGroups.length}
                                            offset={0}
                                            noItemsLabel="No module groups selected"
                                        />
                                    </Grid>
                                    <Grid item xs={3} />
                                </Grid>
                            </React.Fragment>
                            : currentStage === WizardSteps.RemoveModuleOperation ? 
                            <React.Fragment>
                                <Grid container spacing={1} alignItems="flex-start" className={classes.gridHolders} data-testid="delete-module-group-step">
                                    <Grid item xs={3} />
                                    <Grid item xs={6} className={classes.gridHolders}>
                                        <Typography
                                            variant='h6'
                                            className={`${classes.heading} ${classes.ellipsis}`}
                                        >
                                            Selected module group(s) to DELETE
                                        </Typography>
                                        <Autocomplete
                                            id={`delete-combo-box-module-group-selector`}
                                            key={selectModuleGroupDelKey}
                                            options={this.getAvailableModuleGroups(2)}
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => <TextField {...params} label="Select a module group" variant="standard" />}
                                            onChange={(e, pickedValue) => this.onModuleSearch(e, pickedValue, 2)}
                                            data-testid="delete-module-group-form-input"
                                            value={autoSelectModuleGroupDel}
                                            clearOnBlur={true}
                                            blurOnSelect={true}
                                            onBlur={(e) => this.clearAutocompleteSelector(e, false)}
                                        />
                                        <SimpleListPagination
                                            className={classes.simpleList}
                                            keepItemSelected={false}
                                            items={deleteModuleGroupsListItems}
                                            canDeleteListItem={true}
                                            onDeleteItemClick={this.onDeleteListDeleteModule}
                                            rowsPerPage={10}
                                            totalItems={deleteModuleGroups.length}
                                            offset={0}
                                            noItemsLabel="No module groups selected"
                                        />
                                    </Grid>
                                    <Grid item xs={3} />
                                </Grid>
                            </React.Fragment>
                            : currentStage === WizardSteps.Confirmation ? 
                            <React.Fragment>
                                <Grid container spacing={1} alignItems="flex-start" className={classes.gridHolders} data-testid="confirmation-step">
                                    <Grid item xs={4} className={classes.gridHolders}>
                                        <Typography
                                            variant='h6'
                                            className={`${classes.heading} ${classes.ellipsis}`}
                                        >
                                            Selected markers
                                        </Typography>
                                        <Paper className={classes.treePaper}>
                                            {
                                                operationMarkers.map(m => {
                                                    return (
                                                    <Card className={classes.markerCard}>
                                                        <CardContent className={classes.markerCardBody}>
                                                            <Typography variant="h6" component="h6">
                                                                {m.marker.name}
                                                            </Typography>
                                                            <Typography color="textSecondary">
                                                                {m.marker.marker_id}
                                                            </Typography>
                                                            <Typography variant="body2" component="p">
                                                                {m.folder}
                                                                <br />
                                                                {m.floorplan}
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                    );
                                                })
                                            }
                                        </Paper>
                                        <div className={classes.footNote}>
                                            {operationMarkers.length} Markers
                                        </div>
                                    </Grid>
                                    <Grid item xs={4} className={classes.gridHolders}>
                                        <Typography
                                            variant='h6'
                                            className={`${classes.heading} ${classes.ellipsis}`}
                                        >
                                            Module Group(s) to ADD
                                        </Typography>
                                        <SimpleListPagination
                                            className={classes.simpleList}
                                            keepItemSelected={false}
                                            items={addModuleGroupsListItems}
                                            canDeleteListItem={false}
                                            rowsPerPage={10}
                                            totalItems={addModuleGroups.length}
                                            offset={0}
                                            noItemsLabel="No Module Groups selected"
                                        />
                                    </Grid>
                                    <Grid item xs={4} className={classes.gridHolders}>
                                        <Typography
                                            variant='h6'
                                            className={`${classes.heading} ${classes.ellipsis}`}
                                        >
                                            Module Group(s) to DELETE
                                        </Typography>
                                        <SimpleListPagination
                                            className={classes.simpleList}
                                            keepItemSelected={false}
                                            items={deleteModuleGroupsListItems}
                                            canDeleteListItem={false}
                                            rowsPerPage={10}
                                            totalItems={deleteModuleGroupsListItems.length}
                                            offset={0}
                                            noItemsLabel="No Module Groups selected"
                                        />
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                            :
                            <div data-testid="result-step">
                                <CCSpinner
                                    label="Processing Markers"
                                    className={classes.progressContainer}
                                    loading={isContentLoading}
                                    size={200}
                                >
                                    <div className={classes.emptyMarkerHolder}>
                                        <Typography variant="h6" component="h1">
                                            {
                                                processedMarkers > 0 
                                                ?
                                                <React.Fragment>
                                                    All markers have been processed: 
                                                    <br />
                                                    <b>
                                                        Total affected markers {failedMarkers + processedMarkers}
                                                    </b>
                                                    <br />
                                                    <b>
                                                        Successfull({processedMarkers}) / Failed({failedMarkers})
                                                    </b>
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    No operation has been performed: 
                                                    <br />
                                                    <b>
                                                        No marker has been updated
                                                    </b>
                                                </React.Fragment>
                                            }
                                        </Typography>
                                    </div>
                                </CCSpinner>
                            </div>
                        }
                    </div>
                    {
                        currentStage === WizardSteps.MarkerOrigin &&
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.allMarkersButton}
                            onClick={this.loadAllFloorplans}
                            data-testid="load-all-markers-button"
                        >
                            Load all markers in the account and continue
                        </Button>
                    }
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.nextButton}
                        disabled={this.checkNextStageButton()}
                        onClick={this.goNextStageButton}
                        data-testid="go-next-step-button"
                    >
                        {
                            currentStage !== WizardSteps.Result ?
                                'Next'
                                :
                                'Finish'
                        }
                    </Button>
                </div>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(MarkerModuleGroupsWizard);
export {MUIComponent as MarkerModuleGroupsWizard}