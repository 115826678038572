import { Button, Divider, IconButton, TextField } from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles,
    Theme
} from '@material-ui/core/styles';
import { DeleteRounded } from '@material-ui/icons';
import * as React from 'react';
import { Account } from '../../shared/domain';
import { AccountFull } from '../../models/account';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        border: '1px solid #e1e1e1',
        margin: '0.5em 0.5em 2em 0.5em',
        borderRadius: '0.5em'
    },
    simpleFlex: {
        display: 'flex'
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    list: {
        flex: '1 0',
        overflow: 'auto',
        boxShadow: 'none'
    },
    selectInput: {
        margin: '1em'
    },
    textField: {
        padding: '1em',
        width: '100%',
        '& label': {
            fontWeight: 'bolder',
            padding: '1em 0 0 1em',

            fontSize: '1.4em',
            color: 'rgba(0, 0, 0, 0.8) !important'
        },
        '&::before': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            borderBottomStyle: 'solid'
        }
    },
    dividerForm: {
        margin: '1em'
    },
    deleteIcon: {
        color: '#eb445a'
    },
    tooltipText: {
        marginTop: '0.5em',
        fontStyle: 'italic',
        color: 'gray',
        marginBottom: '0',
        marginLeft: '1em',
    },
    addButton: {
        width: '95%',
        margin: 'auto',
        display: 'block',
        marginBottom: '1.5em'
    },
    mainSpinnerLabel: {
        paddingBottom: '30px'
    },
    progressContainer: {
        flexGrow: 1
    },
    teamListContainer: {
        width: '93%',
        display: 'block',
        margin: '1em auto',
    }
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    currentAccount: Account | AccountFull;
    emailDKey: string;
    canEdit: boolean;
    excludedEmailDomains: string[];
    onEmailDomainChange?: (emailDKey: string, emailDList: string[]) => void;
}

interface States {
    internalEmailDList: string[];
    newEmailValue: string;
    isValidEmailDomain: boolean;
}


class SimpleEmailDomainForm extends React.Component<Props, States> {

    generateRandomKey = () => {
        const randomKey = Math.random().toString(36).substring(7);
        return randomKey;
    };

    state: States = {
        internalEmailDList: [],
        newEmailValue: '',
        isValidEmailDomain: false,
    }

    handleDomainChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, keyIndex: number) => {
        const { onEmailDomainChange, emailDKey } = this.props;

        if (!onEmailDomainChange || !emailDKey) {
            return;
        }

        const { internalEmailDList } = this.state;
        
        if (!internalEmailDList) {
            return;
        }

        const newEmailName = e.target.value;

        internalEmailDList[keyIndex] = newEmailName;
        
        onEmailDomainChange(emailDKey, internalEmailDList);
        this.setState({ internalEmailDList });
    }

    handleNewEmail = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { excludedEmailDomains } = this.props;

        const match = e.target.value.match(/(.+)/);
        this.setState({
            newEmailValue: e.target.value,
            isValidEmailDomain: match && excludedEmailDomains.includes(match[1]) ? false : true
        });
    }

    deleteEmailDomain = (keyIndex: number) => {
        const { onEmailDomainChange, emailDKey } = this.props;

        if (!onEmailDomainChange || !emailDKey) {
            return;
        }

        const { internalEmailDList } = this.state;

        if (!internalEmailDList) {
            return;
        }

        internalEmailDList.splice(keyIndex, 1);
        onEmailDomainChange(emailDKey, internalEmailDList);
        this.setState({
            internalEmailDList
        });
    }

    addNewEmailDomain = () => {
        const { onEmailDomainChange, emailDKey } = this.props;
        const { newEmailValue, internalEmailDList } = this.state;
        if (!internalEmailDList || !onEmailDomainChange) {
            return;
        }

        internalEmailDList.push(newEmailValue);
        onEmailDomainChange(emailDKey, internalEmailDList);
        setTimeout(() => {
            this.setState({
                newEmailValue: '',
                internalEmailDList
            });
        }, 500);
    }

    public render() {
        const {
            className,
            classes,
            canEdit
        } = this.props;
        const {
            newEmailValue,
            internalEmailDList,
            isValidEmailDomain,
        } = this.state;

        return (
            <div className={`${classes.root} ${className}`} data-testid="simple-email-domain-form">
                <div className={`${classes.container}`}>
                    {
                       internalEmailDList!.map((e, index) => {
                            return <React.Fragment>
                                <div className={classes.simpleFlex}>
                                    <TextField
                                        className={classes.textField}
                                        id={`${e}`}
                                        label={'Email domain'}
                                        defaultValue={e}
                                        onChange={j => this.handleDomainChange(j, index)}
                                        multiline={true}
                                        placeholder={e}
                                        variant="outlined"
                                        disabled={!canEdit}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        helperText="Public domains are not allowed"
                                    />
                                    <IconButton
                                        className={classes.deleteIcon}
                                        aria-label="delete key from email domain"
                                        onClick={j => this.deleteEmailDomain(index)}
                                    >
                                        <DeleteRounded />
                                    </IconButton>
                                </div>
                                <Divider className={classes.dividerForm} />
                            </React.Fragment>
                        })
                    }
                    {
                        canEdit &&
                        <div>
                            <p className={`${classes.tooltipText}`}>
                                Add a new email domain
                            </p>
                            <TextField
                                className={classes.textField}
                                id="new_email_domain"
                                label={'Email domain'}
                                value={newEmailValue}
                                onChange={e => this.handleNewEmail(e)}
                                multiline={true}
                                placeholder={'New email domain'}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={!isValidEmailDomain && newEmailValue !== ''}
                                helperText="Public domains are not allowed"
                            />
                            {
                                (newEmailValue !== '' && isValidEmailDomain && newEmailValue[0] !== '@') &&
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={`${classes.addButton}`}
                                        onClick={this.addNewEmailDomain}
                                        data-testid="add-email-domain"
                                    >
                                        Add email domain
                                    </Button>
                            }
                        </div>
                    }
                </div>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(SimpleEmailDomainForm);
export {MUIComponent as SimpleEmailDomainForm}