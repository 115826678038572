import { Button, createStyles, Divider, Grid, ListItemText, Paper, TextField, Theme, Typography, withStyles } from "@material-ui/core";
import { WithStyles } from "@material-ui/styles";
import * as React from 'react';
import _ from "lodash";
import { CCSpinner } from "../../shared/components/cc-spinner";
import { AccountFull } from "../../models/account";
import { ModuleGroup } from "../../models/module-group";
import { ModuleGroupForm } from "../module-group-form";
import { SimpleEmailDomainForm } from "../simple-email-domain-form";

export type AccountFormInput = 'string' | 'modules' | 'emails';
export interface accountForm {
    field: string;
    required: boolean;
    input: AccountFormInput;
}

const styles = (theme: Theme) => createStyles({
    [theme.breakpoints.down('lg')]: {
        simpleList: {
            minWidth: '30em'
        },
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    heading: {
        fontWeight: 700,
        marginBottom: '0.5em',
        marginTop: '1em'
    },
    simpleList: {
        height: '100%',
        minWidth: '30em',
    },
    textField: {
        padding: '1em',
        width: '100%',
        '& label': {
            fontWeight: 'bolder',
            padding: '1em 0 0 1em',

            fontSize: '1.4em',
            color: 'rgba(0, 0, 0, 0.8) !important'
        },
        '&::before': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            borderBottomStyle: 'solid'
        }
    },
    listHeader: {
        paddingLeft: '1em',
        '& span': {
            fontWeight: 'bolder'
        }
    },
    dividerForm: {
        margin: '1em'
    },
    detailBorder: {
        border: '1px solid #e1e1e1',
        overflowY: 'scroll',
        height: '100%'
    },
    processingSpinner: {
        position: 'absolute',
        width: '35em',
        height: '100%',
        background: 'rgba(113, 113, 113, 0.47)',
        zIndex: 9
    },
    saveButton: {
        marginTop: '1em',
        marginBottom: '1em'
    }
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    accountModuleGroups: ModuleGroup[];
    pickedAccount: AccountFull | undefined;
    formFields: accountForm[];
    hiddenAttributes: string[];
    isProcessing: boolean;
    isCreating: boolean;
    onFormSubmit?: (formAccount: AccountFull) => void;
    onFormChanges?: () => void;
}

interface States {
    accountInForm: AccountFull;
    formList: React.ReactChild[];
    canSubmit: boolean;
    excludedEmailDomains: string[];
}

class AccountForm extends React.Component<Props, States> {

    state: States = {
        accountInForm: {} as AccountFull,
        formList: [],
        canSubmit: false,
        excludedEmailDomains: []
    }

    componentDidMount() {
        const { pickedAccount } = this.props;
        if (!pickedAccount) {
            return;
        }
        const accountInForm = {...pickedAccount};
        const excludedEmailDomains = `${process.env.REACT_APP_EXCLUDED_DOMAIN_LIST}`.split(',');

        this.setState({accountInForm, excludedEmailDomains}, () => {
            this.formatAccountForm();
        });
    }

    handleStringChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: string) => {
        const { accountInForm } = this.state;
        const { onFormChanges } = this.props;

        if (!accountInForm) {
            return;
        }

        if (!onFormChanges) {
            return;
        }

        _.set(accountInForm, key, e.target.value);
        this.setState({accountInForm, canSubmit: true}, () => {
            onFormChanges();
        });
    }

    formatFieldName = (teamKey: string) => {
        const lowerWords = teamKey.split("_").join(" ");
        return lowerWords.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    }

    handleEmailDomainChange = (key: string, newEmailDomains: string[]) => {
        const { accountInForm } = this.state;
        const { onFormChanges } = this.props;

        if (!accountInForm) {
            return;
        }

        if (!onFormChanges) {
            return;
        }

        _.set(accountInForm, key, newEmailDomains);
        this.setState({accountInForm, canSubmit: true}, () => {
            onFormChanges();
        });
    }

    formatAccountForm = () => {
        const { formFields, classes, hiddenAttributes, isCreating, accountModuleGroups } = this.props;
        const { accountInForm, excludedEmailDomains } = this.state;
        const formattedFormFields: React.ReactChild[] = [];
        for (const key in accountInForm) {
            if (hiddenAttributes.includes(key)) {
                continue;
            }
            const keyValue = accountInForm[key as keyof AccountFull];
            const keyName = this.formatFieldName(key);
            // CHECK IF THE FIELD IS EDITABLE OR NOT
            const formType = formFields.find(e => e.field === key);
            if (formType) {
                // IS EDITABLE
                // CHECK TYPE
                switch (formType.input) {
                    case 'string':
                        formattedFormFields.push(
                            <TextField
                                className={classes.textField}
                                id={`${accountInForm.account_id}_${key}`}
                                label={`${keyName} ${isCreating && formType.required ? '* ' : ''}`}
                                defaultValue={keyValue}
                                onChange={e => this.handleStringChange(e, key)}
                                multiline={false}
                                placeholder={keyName}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        );
                        break;
                    case 'modules':
                        if (accountInForm.module_groups) {
                            // ADD THE HEADER
                            formattedFormFields.push(
                                <ListItemText className={classes.listHeader} primary={keyName} />
                            );
                            // USE THE MODULE PICKER
                            formattedFormFields.push(
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs={1}>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <ModuleGroupForm
                                            existingModuleList={accountInForm.module_groups}
                                            moduleGroups={accountModuleGroups}
                                            moduleKey={"module_groups"}
                                            canEdit={false}
                                        />
                                    </Grid>
                                </Grid>
                            );
                        }
                        break;
                    case 'emails':
                            // ADD THE HEADER
                            formattedFormFields.push(
                                <ListItemText className={classes.listHeader} primary="Valid email domains for user registration" />
                            );
                            // USE THE EMAIL DOMAIN PICKER
                            formattedFormFields.push(
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs={1}>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <SimpleEmailDomainForm
                                            currentAccount={accountInForm}
                                            emailDKey={key}
                                            canEdit={true}
                                            excludedEmailDomains={excludedEmailDomains}
                                            onEmailDomainChange={this.handleEmailDomainChange}
                                        />
                                    </Grid>
                                </Grid>
                            );
                        break;
                    default:
                        break;
                }
            } else {
                // NOT EDITABLE
                if (typeof keyValue === 'string' || typeof keyValue === 'number') {
                    if (keyValue !== '') {
                        formattedFormFields.push(
                            <TextField
                                className={classes.textField}
                                id={`${accountInForm.account_id}_${key}`}
                                label={keyName}
                                defaultValue={keyValue !== '' ? keyValue : '-'}
                                disabled={true}
                                multiline={true}
                            />
                        );
                    }
                } else if (typeof keyValue === 'object') {
                    switch (key) {
                        case 'meta':
                            if (accountInForm.meta.cleaningmaps) {
                                formattedFormFields.push(<ListItemText className={classes.listHeader} primary="Cleaning Maps" />);
                                formattedFormFields.push(
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs={1}>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <TextField
                                                className={classes.textField}
                                                id={`${accountInForm.account_id}_${key}`}
                                                label="DB Name"
                                                defaultValue={accountInForm.meta.cleaningmaps.dbname}
                                                disabled={true}
                                                multiline={true}
                                            />
                                        </Grid>
                                    </Grid>,
                                    <Divider className={classes.dividerForm} />
                                );
                            }
                            break;
                        case 'email_domain_sso':
                            if (accountInForm.email_domain_sso) {
                                formattedFormFields.push(
                                    <TextField
                                        className={classes.textField}
                                        id={`${accountInForm.account_id}_${key}`}
                                        label={keyName}
                                        defaultValue={keyValue ? keyValue.join(', ') : '-'}
                                        disabled={true}
                                        multiline={true}
                                    />
                                );
                            }
                            break;
                        default:
                            break;
                    }
                }
            }
        }

        this.setState({formList: formattedFormFields});
    }

    onSubmitChanges = () => {
        const { onFormSubmit } = this.props;
        const { accountInForm } = this.state;

        if (!onFormSubmit || this.checkValidForm()) {
            return;
        }

        onFormSubmit(accountInForm);
    }

    checkValidForm = () => {
        const { formFields } = this.props;
        
        const { accountInForm } = this.state;
        let flags = [];
        for (const item of formFields) {
            if (item.required) {
                switch (item.input) {
                    case 'string':
                        flags.push(accountInForm[item.field as keyof AccountFull] === '');
                        break;
                    case 'emails':
                        flags.push(accountInForm.email_domain_sso!.length <= 0);
                        break;
                }
            }
        }
        return flags.includes(true);
    }

    public render() {
        const {
            className,
            classes,
            pickedAccount,
            isProcessing,
            isCreating
        } = this.props;
        const {
            formList,
            canSubmit,
        } = this.state;

        return (
            <div className={`${classes.root} ${className}`} data-testid="account-form">
                <div className={`${classes.container} ${classes.simpleList}`}>
                    <CCSpinner
                        loading={isProcessing}
                        size={70}
                        className={classes.processingSpinner}
                    />
                    <Typography
                        variant='h6'
                        className={`${classes.heading}`}
                    >
                        { isCreating ? 'Creating new account' : `Editing: ${pickedAccount!.name}` }
                    </Typography>
                    { canSubmit && 
                        <Button
                            className={classes.saveButton}
                            variant="contained"
                            color="primary"
                            onClick={this.onSubmitChanges}
                            data-testid="submit-form-button"
                            disabled={this.checkValidForm()}
                        >
                            { isCreating ? 'Create Account' : 'Save Changes'}
                        </Button>
                    }
                    <Paper className={`${classes.container} ${classes.detailBorder}`}>
                        { formList }
                    </Paper>
                </div>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(AccountForm);
export {MUIComponent as AccountForm};
