import { Button, Chip, Drawer, IconButton, Typography } from '@material-ui/core';
import {
    createStyles,
    Theme,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import {
    Close,
    Error
} from '@material-ui/icons';
import _ from 'lodash';
import {
    inject,
    observer
} from 'mobx-react';
import * as React from 'react';
import {
    AccountFolderMarker,
    FloorplanMarkerDetail,
    MarkerMetaFieldsWizard,
    RunningTasksList,
    MarkerModuleGroupsWizard
} from '../../components';
import { FloorPlan, Marker } from '../../models';
import { ModuleGroup, UserModuleGroup } from '../../models/module-group';
import AccountProvider from '../../providers/account.provider';
import FloorplanProvider from '../../providers/floorplan.provider';
import FolderProvider from '../../providers/folder.provider';
import JobProvider, { RunningTasks, STATIC_PROGRESS_LIMIT, StatusTypes } from '../../providers/job.provider';
import MarkerProvider from '../../providers/marker.provider';
import ModuleGroupProvider from '../../providers/modulegroup.provider';
import { CCSpinner } from '../../shared/components/cc-spinner';
import { RenderTree } from '../../shared/components/node-tree';
import { DialogResult, SimpleDialog } from '../../shared/components/simple-dialog';
import { PageBoundary } from '../../shared/components/simple-grid-pagination';
import { SimpleModal } from '../../shared/components/simple-modal';
import {
    Account, Folder,
} from '../../shared/domain';
import { MainTabs } from '../../stores/admin.store';
import { RootStore } from '../../stores/root.store';
import { PickedType } from '../marker-export/marker-export';
import { UserModule } from '../../models/user-module';
import UserModuleProvider from '../../providers/usermodule.provider';
import UserModuleGroupProvider from '../../providers/usermodulegroup.provider';

const ROWS_PER_PAGE = 10;
const ACCOUNTS_PAGING_LIMIT = 20;
const MARKER_HIDDEN_ATTRIBUTES = [
    '_rev',
    'type'
]

export interface MarkerSourceItem {
    id: string,
    name: string,
    type: MarkerSourceType
}

export type MarkerSearchCategory = 'MARKER_NAME' | 'MARKER_ID' | 'FOLDER_NAME' | 'FLOORPLAN_NAME'; 
export type MarkerSourceType = 'Floorplan' | 'Folder' | 'CSV';

const styles = (theme: Theme) => createStyles({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row'
    },
    analyticsTabList: {
        height: '18.2em'
    },
    errorPopup: {
        width: '98%',
        borderRadius: 5
    },
    errorPopupHeader: {
        height: '0.6em',
        backgroundColor: theme.palette.error.main,
        borderRadius: '3px 3px 0 0'
    },
    errorPopupContentContainer :{
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(1),
    },
    errorPopupIcon: {
        fontSize: 40,
        marginRight: '1em'
    },
    mainSpinnerLabel: {
        paddingBottom: '30px'
    },
    progressContainer: {
        flexGrow: 1
    },
    animContainer: {
        transition: 'width 1s'
    },
    listIcon: {
        marginRight: '0.5em',
        fontSize: '1em'
    },
    accountFullSize: {
        width: '100%'
    },
    accountMediumSize: {
        width: '60%'
    },
    accountSmallSize: {
        width: '50%',
    },
    accountCollapsedSize: {
        width: 'auto'
    },
    markerContainerSize: {
        flex: 1,
        marginLeft: '2em'
    },
    tasksContainerSize: {
        width: '30em',
        padding: '0 1em',
    },
    paper: {
        zIndex: 'auto'
    },
    jobMenuButton: {
        position: 'fixed',
        right: '6%',
        top: '3%',
    },
    moduleGroupOperationButton: {
        position: 'fixed',
        left: '27%',
        top: '3%',
        [theme.breakpoints.down('lg')]: {
            left: '37%',
            top: '4%'
        },
    },
    markerMetaUpdatesButton: {
        position: 'fixed',
        left: '40%',
        top: '3%',
        [theme.breakpoints.down('lg')]: {
            left: '50%',
            top: '4%'
        },
    },
    notFinishedBadge: {
        marginLeft: '1em',
        background: '#ffb300',
        color: 'white',
        fontWeight: 'bolder',
    },
    finishedBadge: {
        marginLeft: '1em',
        background: '#107d00',
        color: 'white',
        fontWeight: 'bolder',
    },
    wizardPopup: {
        width: '80%',
        borderRadius: 5
    },
    wizardPopupHeader: {
        height: '2.5em',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '3px 3px 0 0',
        color: 'white',
        padding: '0.5em',
        fontSize: '1.5em'
    },
    wizardContentContainer :{
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(1),
    },
    wizardHolder: {
        width: '100%'
    },
    modalHeaderBar: {
        display: 'flex',
        alignItems: 'flex-start'
    },
    closeButton: {
        display: 'block',
        marginLeft: 'auto',
        color: 'white',
        padding: '5px'
    },
    spinnerOverlay: {
        zIndex: 2000
    },
});

interface Props extends WithStyles<typeof styles> {
    rootStore: RootStore;
    onAuthError?: () => void;
};

interface States {
    accounts: Account[];
    currentAccount: Account | null;
    currentAccountOffset: number;
    networkError: boolean;
    dataPageIsLoading: boolean;
    isAccountsLoading: boolean;
    totalAccounts: number;
    mainSpinnerText: string;
    folders: Folder[];
    isFoldersLoading: boolean;
    currentNodeTree: RenderTree;
    treeIsLoading: boolean;
    treePickedType: PickedType;
    treePickedId: string;
    treePickedName: string;
    processedFolders: string[];
    processedFoldersWMarkers: string[];
    markers: Marker[];
    totalMarkers: number;
    markersIsLoading: boolean;
    currentMarker: Marker | null;
    currentMarkerOffset: number;
    isMarkerList: boolean;
    collapseAccounts: boolean;
    wasMarkerUpdated: boolean;
    moduleGroups: ModuleGroup[];
    userModules: UserModule[];
    isFormLoading: boolean;
    runningTasks: RunningTasks[];
    currentTasksOffset: number;
    timeElapser: number | null;
    isErrorModalOpened: boolean;
    errorModalHeader: string;
    errorModalText: string;
    isJobMenuOpen: boolean;
    isNotificationModalOpened: boolean;
    notificationModalHeader: string;
    notificationModalText: string;
    isModuleGroupWizardOpen: boolean;
    isMetaFieldsWizardOpen: boolean;
    accountModuleGroups: ModuleGroup[];
    accountUserModules: UserModule[];
    formHasChanges: boolean;
    isChangesModalOpen: boolean;
    tempTreeNodeId: string;
    tempTreeNodeName: string;
    searchAccountName: string;
    storedAccounts: Account[];
    storedAccountOffset: number;
    storedAccountTotalCount: number;
}

@inject('rootStore')
@observer
class MarkerDetail extends React.Component<Props, States> {
    public static defaultProps: Partial<Props> = {
    };

    state: States = {
        accounts: [],
        currentAccount: null,
        currentAccountOffset: 0,
        networkError: false,
        dataPageIsLoading: true,
        isAccountsLoading: false,
        totalAccounts: 0,
        mainSpinnerText: 'Please wait. Loading data.',
        folders: [],
        isFoldersLoading: false,
        currentNodeTree: {id: '', name:'', children:[], path: ''},
        treeIsLoading: false,
        treePickedType: PickedType.None,
        treePickedId: '',
        treePickedName: '',
        processedFolders: [],
        processedFoldersWMarkers: [],
        markers: [],
        totalMarkers: 0,
        markersIsLoading: false,
        currentMarker: null,
        currentMarkerOffset: 0,
        isMarkerList: false,
        collapseAccounts: false,
        wasMarkerUpdated: false,
        moduleGroups: [],
        userModules: [],
        isFormLoading: false,
        runningTasks: [],
        currentTasksOffset: 0,
        timeElapser: null,
        isErrorModalOpened: false,
        errorModalHeader: '',
        errorModalText: '',
        isJobMenuOpen: false,
        isNotificationModalOpened: false,
        notificationModalHeader: '',
        notificationModalText: '',
        isModuleGroupWizardOpen: false,
        isMetaFieldsWizardOpen: false,
        accountModuleGroups: [],
        accountUserModules: [],
        formHasChanges: false,
        isChangesModalOpen: false,
        tempTreeNodeId: '',
        tempTreeNodeName: '',
        searchAccountName: '',
        storedAccounts: [],
        storedAccountOffset: 0,
        storedAccountTotalCount: 0
    };

    accountProvider = new AccountProvider();
    folderProvider = new FolderProvider();
    floorplanProvider = new FloorplanProvider();
    markerProvider = new MarkerProvider();
    moduleGroupProvider = new ModuleGroupProvider();
    userModuleGroupProvider = new UserModuleGroupProvider();
    userModuleProvider = new UserModuleProvider();
    jobProvider = new JobProvider();

    componentDidMount() {
        this.fetchAccounts(this.state.currentAccountOffset);
    };

    componentWillUnmount() {
        const {
            rootStore: { adminStore }
        } = this.props;   
        const { runningTasks } = this.state;
        this.clearIntervals(runningTasks);
        adminStore.setCurrentExportTasks(runningTasks, MainTabs.Marker_Detail);
        this.clearTimeElapserInterval();
    }

    clearIntervals(tasks: RunningTasks[]) {
        tasks.forEach((element) => {
            element.set_to_stop = true;
        });
    }

    clearTimeElapserInterval() {
        const { timeElapser } = this.state;
        if( timeElapser ) {
            window.clearInterval(timeElapser);
        }
    } 

    startTimeInterval() {
        const { timeElapser } = this.state;
        if (timeElapser) {
            return;
        }
        const timeElapserInterval = window.setInterval(() => {
            const { runningTasks } = this.state;
            const time = Date.now();
            const processing = runningTasks.filter(e => e.status === 102);
            processing.forEach(element => {
                element.elapsedTime = Math.round((time - element.startedAt) / 1000);
            });
            this.setState({runningTasks});
        }, 1000);
        this.setState({timeElapser: timeElapserInterval});
    }

    fetchRunningTasks = () => {
        const {
            rootStore: { adminStore }
        } = this.props;
        const tasks = adminStore.currentExportTasks.find(e => e.page === MainTabs.Marker_Detail);
        this.setState({
            runningTasks: tasks!.tasks
        }, () => {
            tasks!.tasks.forEach(element => {
                if(element.status === 102) {
                    // SET TO RESTART POOLING
                    element.set_to_stop = false;
                    this.startTaskWatcher(element);
                }
            });
        });
    }

    startTaskWatcher = (task: RunningTasks) => {
        const { runningTasks } = this.state;

        if (!task.export_name || !task.task_id || !task.export_id) {
            return;
        }

        const statusCallback = (taskId: string) => {
            const taskIndex = runningTasks.findIndex(e => e.task_id === taskId);
            const taskItem = runningTasks.find(e => e.task_id === taskId);

            if (!taskItem || taskItem.status !== 102) {
                return;
            }

            if (taskItem.set_to_stop) {
                return;
            }   
            
            this.jobProvider.checkJobStatus(taskItem.task_id).then(data => {
                if (data.job_status === StatusTypes.Error) {
                    taskItem.status = 500;
                    runningTasks[taskIndex] = taskItem;
                    this.setState({runningTasks});
                }
                if (data.job_status === StatusTypes.Complete) {
                    taskItem.status = 200;
                    taskItem.progress = 100.0;
                } else {
                    // KEEP TRACK OF THE PROGRESS STATUS IF ITS STILL THE SAME
                    if (taskItem.progress === data.estimate_percent_complete) {
                        taskItem.static_progress_count += 1;
                    }
                    taskItem.progress = data.estimate_percent_complete;
                    // IF WE REACH A POINT WHERE THE STATUS IS THE SAME THE JOB IS SLOW
                    // MAKE FEWER REQUESTS TO NOT OVERWHELM THE STATUS ENDPOINT
                    if (taskItem.static_progress_count === STATIC_PROGRESS_LIMIT) {
                        taskItem.timer += 1000;
                        taskItem.static_progress_count = 0;
                    }
                    setTimeout(taskItem.internalCallback!, taskItem.timer, taskId);
                }
            }).catch((error) => {
                const { status } = error.response;
                // CHECK IF WE TIMEOUT OR NOT
                if (status === 408 || status === 504) {
                    // WE HAVE TIMEOUT INCREASE TIMER AND KEEP TRYING
                    taskItem.timer += 1000;
                    setTimeout(taskItem.internalCallback!, taskItem.timer, taskId);
                    return;
                }
                taskItem.status = 500;
                runningTasks[taskIndex] = taskItem;
                this.setState({runningTasks});
            });
        }

        // PREVENT GENERATING TIMERS WITHOUT PROPERTIES BEING INITIALIZED
        const taskIndex = runningTasks.findIndex(e => e.task_id === task.task_id);
        runningTasks[taskIndex].internalCallback = statusCallback;
        this.setState({runningTasks}, () => {
            setTimeout(statusCallback, task.timer, task.task_id);
        });
    }

    fetchAccounts = (offset:number, limit: number = ACCOUNTS_PAGING_LIMIT) => {
        return this.accountProvider.getAllAccounts(offset, limit)
          .then((results) => {
            const {accounts, totalCount} = results;
            if (!accounts) {
                if (offset === 0) {
                    this.setState({networkError: true});
                }
                return;
            }

            this.fetchModuleGroups();
            this.fetchUserModules();
            this.setState({
                isAccountsLoading: false,
                totalAccounts: totalCount,
                accounts,
                currentAccountOffset: offset
            });
        }).catch((error) => {
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({networkError: true});
        });
    }

    searchAccounts = (searchName: string, offset?: number) => {
        // Nothing to search load the normal account list
        if (searchName === '') {
            const {
                storedAccounts,
                storedAccountOffset,
                storedAccountTotalCount
            } = this.state;

            // Empty search do nothing
            if (!storedAccounts.length) {
                return;
            }

            // Going back from actual seach
            this.setState({
                accounts: storedAccounts,
                currentAccountOffset: storedAccountOffset,
                totalAccounts: storedAccountTotalCount,
                searchAccountName: '',
                storedAccounts: [],
                storedAccountOffset: 0,
                storedAccountTotalCount: 0
            });
            return;
        }

        // We have a name search
        const {
            currentAccountOffset,
            accounts,
            totalAccounts,
            storedAccounts
        } = this.state;
        this.setState({
            isAccountsLoading: true,
            searchAccountName: searchName
        }, () => {
            const searchOffset = offset ? offset : 0;
            return this.accountProvider
                .searchAccountByNameShortSchema(searchName, searchOffset, ACCOUNTS_PAGING_LIMIT)
                .then(results => {
                    const { accounts: foundAccounts, totalCount } = results;
                    if (!foundAccounts) {
                        if (currentAccountOffset === 0) {
                            this.setState({ networkError: true });
                        }
                        return;
                    }
    
                    // If we haven't stored the original list do it
                    // This prevents multiple searchs overriding each others
                    if (!storedAccounts.length) {
                        this.setState({
                            storedAccounts: accounts,
                            storedAccountTotalCount: totalAccounts,
                            storedAccountOffset: currentAccountOffset
                        })
                    }

                    this.setState({
                        isAccountsLoading: false,
                        totalAccounts: totalCount,
                        accounts: foundAccounts,
                        currentAccountOffset: searchOffset
                    });
                })
                .catch(error => {
                    const { status } = error.response;
                    if (status === 401) {
                        const { onAuthError } = this.props;
                        if (!onAuthError) {
                            return;
                        }
                        onAuthError();
                        return;
                    }
                    this.setState({ networkError: true });
                });
        });
    }

    fetchModuleGroups = () => {
        return this.moduleGroupProvider.fetchModuleGroups()
          .then((results) => {
            const {modules} = results;

            this.setState({
                moduleGroups: modules,
                dataPageIsLoading: false
            });
        }).catch((error) => {
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({networkError: true});
        });
    }


    fetchUserModules = () => {
        return this.userModuleProvider.fetchUserModules()
          .then((results) => {
            const {modules} = results;

            this.setState({
                userModules: modules
            });
        }).catch((error) => {
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({networkError: true});
        });
    }

    onAccountDataPageOverBoundaryReached = (boundary: PageBoundary, nextPage: number): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const { 
                currentAccountOffset,
                totalAccounts
            } = this.state;
            const isFirstPage = nextPage === 0;
            const isLastPage = nextPage === (Math.ceil(totalAccounts / ROWS_PER_PAGE)-1);
            const newOffset = isFirstPage ? 0
                            : isLastPage ? ACCOUNTS_PAGING_LIMIT * Math.floor(totalAccounts / ACCOUNTS_PAGING_LIMIT)
                            : (boundary === PageBoundary.Upper) ? 
                                currentAccountOffset+ACCOUNTS_PAGING_LIMIT :
                                currentAccountOffset-ACCOUNTS_PAGING_LIMIT;
            this.setState({isAccountsLoading: true});
            this.fetchAccounts(newOffset)
              .then(() => resolve())
              .catch(()=> reject());
        });
    };

    onAccountClicked = (account: Account) => {
        const { currentAccount } = this.state;
        if (currentAccount === account) {
            return;
        }

        this.setState({
            currentAccount: account,
            isMarkerList: false
        });
        this.fetchFoldersFromAccount(account);
        this.fetchAccountModuleGroups(account);
        this.fetchAccountUserModules(account);
    };

    fetchFoldersFromAccount = (account: Account) => {
        this.setState({
            isFoldersLoading: true,
            treePickedType: PickedType.None,
            treePickedId: '',
            processedFolders: []
        });

        return this.accountProvider.getRootFoldersByAccountId(account.accountId)
            .then((results) => {
                const folders: Folder[] = results;
                if(!folders) {
                    this.setState({networkError: true});
                    return;
                }

                // TRANSFORM THE FOLDERS INTO TREE VIEW
                const accountTree: RenderTree = {
                    id: account.accountId,
                    name: account.name,
                    children: [],
                    path: ''
                };

                const treeNodes: RenderTree[] = folders.map(element => {
                    const newItem = {
                        id: element.id,
                        name: element.name,
                        children: [],
                        path: `${element.id}__`
                    }
                    return newItem as RenderTree;
                });

                accountTree.children = treeNodes;

                this.setState({
                    isFoldersLoading: false,
                    folders,
                    currentNodeTree: accountTree
                });

        }).catch((error) => {
            // tslint:disable-next-line:no-console
            console.error(error);
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({networkError: true});
        });

    };

    fetchAccountModuleGroups = (account: Account) => {
        this.setState({accountModuleGroups: []});
        this.accountProvider.fetchModuleGroupsFromAccountId(account.accountId)
            .then((data) => {
                const { module_groups } = data;
                this.setState({
                    accountModuleGroups: module_groups
                });
            }).catch((error) => {
                // tslint:disable-next-line:no-console
                console.error(error);
                const { status } = error.response;
                if (status === 401) {
                    const { onAuthError } = this.props;
                    if (!onAuthError) {
                        return;
                    }
                    onAuthError();
                    return;
                }
                this.setState({networkError: true});
            });
    };

    fetchAccountUserModules = (account: Account) => {
        this.setState({accountUserModules: []});
        this.accountProvider.fetchUserModulesFromAccountId(account.accountId)
            .then((data) => {
                const { user_modules } = data;
                this.setState({
                    accountUserModules: user_modules
                })
            }).catch((error) => {
                // tslint:disable-next-line:no-console
                console.error(error);
                const { status } = error.response;
                if (status === 401) {
                    const { onAuthError } = this.props;
                    if (!onAuthError) {
                        return;
                    }
                    onAuthError();
                    return;
                }
                this.setState({networkError: true});
            });
    }

    deepTreeSearch = (treeData: RenderTree, searchId: string) => {
        let foundTree: RenderTree | undefined;
        let searchLevel: RenderTree[] | undefined = treeData.children;
        do {
            foundTree = searchLevel?.find((e: RenderTree) => e.id === searchId);
            let tempLevel: any = [];
            searchLevel?.forEach((element: RenderTree) => {
                tempLevel = tempLevel.concat(element.children);
            });
            searchLevel = tempLevel;
        } while(!foundTree);
        return foundTree;
    }

    updateChildrenOnPath = (treeData: RenderTree, nodeId: string, children: RenderTree[]) => {
        treeData.children!.forEach(element => {
            if (_.isEqual(element.id, nodeId)) {
                element.children = children;
            } else {
                this.updateChildrenOnPath(element, nodeId, children);
            }
        });
    }

    onNodeSelected = (treeData: RenderTree , nodeId: string, nodeLabel: string, updateCallback: (nodeIds: string[]) => void) => {
        const { folders, currentAccount, processedFolders, formHasChanges, processedFoldersWMarkers } = this.state;
        if(currentAccount!.accountId === nodeId) {
            this.setState({
                treePickedType: PickedType.None,
                treePickedId: '',
                treePickedName: ''
            });
            return;
        }
        this.setState({treeIsLoading: true});
        // CHECK PICK TYPE
        const currentNodePos = this.deepTreeSearch(treeData, nodeId);
        const nodePickedType = folders.find(e => e.id === nodeId)
            ? !processedFoldersWMarkers.includes(nodeId)
                ? PickedType.None
                : PickedType.Folder
            : PickedType.FloorPlan;
        // NOT PROCESSED FOLDER
        if(nodePickedType === PickedType.None) {
            // DID WE ALREADY CHECK FOR CHILDREN?
            if (processedFolders.find(e => e === nodeId)) {
                // FOLDER CHILDREN
                // NOT SELECTABLE TO LOAD
                this.setState({
                    treePickedType: PickedType.None,
                    currentNodeTree: treeData,
                    treeIsLoading: false
                });
                return;
            }
            // EMPTY
            // CHECK FOR FOLDER CHILDREN FIRST
            const currentPath = currentNodePos.path.slice(0, -2);
            processedFolders.push(nodeId);
            return this.accountProvider.getChildrenFolderByFolderId(currentAccount!.accountId, nodeId)
                .then((results: Folder[]) => {
                    if (results.length > 0) {
                        const nodes: RenderTree[] = results.map(element => {
                            const newItem = {
                                id: element.id,
                                name: element.name,
                                children: [],
                                path: `${currentNodePos.path}${element.id}__`
                            }
                            return newItem as RenderTree;
                        });
                        this.updateChildrenOnPath(treeData, nodeId, nodes);
                        updateCallback([currentAccount!.accountId, ...currentPath.split('__'), ...nodes.map(e => e.id)]);
                        this.setState({
                            treePickedType: PickedType.None,
                            treePickedId: '',
                            treePickedName: '',
                            currentNodeTree: treeData,
                            treeIsLoading: false,
                            folders: folders.concat(results),
                            processedFolders,
                            markers: [],
                            currentMarker: null,
                            currentMarkerOffset: 0,
                            totalMarkers: 0,
                        });
                        return;
                    } else {
                        return this.folderProvider.fetchFloorplans(nodeId)
                            .then((floorplans) => {
                                if (floorplans.length > 0) {
                                    const nodes: RenderTree[] = floorplans.map((element: FloorPlan) => {
                                        const newItem = {...element} as RenderTree;
                                        newItem.children = [];
                                        newItem.path = `${currentNodePos.path}${element.id}__`;
                                        return newItem;
                                    });
                                    this.updateChildrenOnPath(treeData, nodeId, nodes);
                                    updateCallback([currentAccount!.accountId, ...currentPath.split('__'), ...nodes.map(e => e.id)]);
                                    // THIS FOLDER FOLDER HAS FLOORPLAN
                                    // IT IS NOT SELECTABLE
                                    this.setState({
                                        treePickedType: PickedType.None,
                                        treePickedId: '',
                                        treePickedName: '',
                                        currentNodeTree: treeData,
                                        treeIsLoading: false,
                                        processedFolders,
                                        markers: [],
                                        currentMarker: null,
                                        currentMarkerOffset: 0,
                                        totalMarkers: 0,
                                    });
                                    return;
                                }
                                // NO FLOORPLANS FOUND
                                // CHECK IF IT HAS MARKERS
                                return this.folderProvider.fetchFolderMarkers(nodeId).then((folderMarkers) => {
                                    const { markers } = folderMarkers;

                                    if (markers.length <= 0) {
                                        // NO MARKERS FOUND
                                        // CAN'T DO ANYTHING
                                        this.setState({
                                            treePickedType: PickedType.None,
                                            treePickedId: '',
                                            treePickedName: '',
                                            currentNodeTree: treeData,
                                            treeIsLoading: false,
                                            processedFolders,
                                            markers: [],
                                            currentMarker: null,
                                            currentMarkerOffset: 0,
                                            totalMarkers: 0,
                                        });
                                        return;
                                    }

                                    processedFoldersWMarkers.push(nodeId);
                                    this.fetchFolderMarkers(nodeId, currentNodePos.name);
                                }).catch((error) => {
                                    this.onNodeSelectError(error);
                                })
                        }).catch((error) => {
                            this.onNodeSelectError(error);
                        });
                    }
            }).catch((error) => {
               this.onNodeSelectError(error); 
            });
        }

        this.setState({treeIsLoading: false});

        if (!formHasChanges) {
            this.setState({
                markers: [],
                markersIsLoading: true,
                currentMarker: null,
                currentMarkerOffset: 0,
                totalMarkers: 0,
                treePickedType: nodePickedType,
                treePickedId: nodeId,
                treePickedName: currentNodePos.name,
                tempTreeNodeId: nodeId,
                tempTreeNodeName: currentNodePos.name
            }, () => {
                if (nodePickedType === PickedType.FloorPlan){
                    this.fetchFloorplanMarkers(nodeId, currentNodePos.name);
                    return;
                }
                this.fetchFolderMarkers(nodeId, currentNodePos.name);
            });
            return;
        }

        const { treePickedId } = this.state;

        if (treePickedId === nodeId) {
            return;
        }

        this.setState({
            isChangesModalOpen: true,
            tempTreeNodeId: nodeId,
            tempTreeNodeName: currentNodePos.name
        });
        return;
    }

    onNodeSelectError = (error: any) => {
        // tslint:disable-next-line:no-console
        console.error(error);
        const { status } = error.response;
        if (status === 401) {
            const { onAuthError } = this.props;
            if (!onAuthError) {
                return;
            }
            onAuthError();
            return;
        }
        this.setState({networkError: true});
    }

    onNodeToggled = (nodeIds: string[]) => {
        return;
    }

    fetchFloorplanMarkers = (floorplanId: string, floorplanName: string) => {
        this.setState({
            treeIsLoading: true,
            markers: [],
            markersIsLoading: true,
            currentMarker: null,
            currentMarkerOffset: 0,
            totalMarkers: 0,
            treePickedType: PickedType.FloorPlan,
            treePickedId: floorplanId,
            treePickedName: floorplanName,
            tempTreeNodeId: floorplanId,
            tempTreeNodeName: floorplanName
        });
        this.floorplanProvider.fetchMarkers(floorplanId).then(data => {
            const markers = data as Marker[];
            this.setState({
                treeIsLoading: false,
                markers: markers,
                markersIsLoading: false,
                isMarkerList: true,
                totalMarkers: markers.length
            });
        }).catch((error) => {
            // tslint:disable-next-line:no-console
            console.error(error);
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({networkError: true});
        });
    }

    fetchFolderMarkers = (folderId: string, folderName: string) => {
        this.setState({
            treeIsLoading: true,
            markers: [],
            markersIsLoading: true,
            currentMarker: null,
            currentMarkerOffset: 0,
            totalMarkers: 0,
            treePickedType: PickedType.Folder,
            treePickedId: folderId,
            treePickedName: folderName,
            tempTreeNodeId: folderId,
            tempTreeNodeName: folderName
        });
        this.folderProvider.fetchFolderMarkers(folderId).then(data => {
            const { markers } = data;
            this.setState({
                treeIsLoading: false,
                markers: markers,
                markersIsLoading: false,
                isMarkerList: true,
                totalMarkers: markers.length
            });
        }).catch((error) => {
            // tslint:disable-next-line:no-console
            console.error(error);
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({networkError: true});
        });
    }

    onMarkerClick = (marker: Marker) => {
        if (!marker) {
            return;
        }

        // CHECK IF THE MARKER HAS THE META PROPERTY
        if (!marker.meta) {
            marker.meta = {
                type: ''
            }
        } else {
            // CHECK IF THE MARKER HAS THE TYPE PROPERTY
            if (!marker.meta.type) {
                marker.meta.type = '';
            }
        }

        this.setState({currentMarker: marker});
    }

    onCollapseAccounts = (collapseAccounts: boolean) => {
        this.setState({collapseAccounts});
    }

    onMarkerSaveChanges = (marker: Marker, markerUserModuleGroup: UserModuleGroup | null) => {
        if (!marker) {
            return;
        }
        this.setState({
            mainSpinnerText: 'Saving Marker Changes',
            dataPageIsLoading: true,
        });
        
        if (!markerUserModuleGroup || !markerUserModuleGroup.has_updates) {
            this.updateMarker(marker);
            return;
        }

        if (markerUserModuleGroup.flag_to_clone) {
            this.cloneUserModuleGroup(marker, markerUserModuleGroup);
            return;
        }

        if (markerUserModuleGroup.has_updates) {
            this.updateUserModuleGroup(marker, markerUserModuleGroup);
            return;
        }
        
        this.updateMarker(marker);
    }
    
    cloneUserModuleGroup = (marker: Marker, userModuleGroup: UserModuleGroup) => {
        this.userModuleGroupProvider.createUserModuleGroup(userModuleGroup).then(data => {
            const { moduleGroup } = data;
            marker.user_module_group = moduleGroup.module_group_id;
            this.updateMarker(marker);
        }, error => {
            // tslint:disable-next-line:no-console
            console.error(error);
            this.setState({networkError: true});
        });
    }

    updateUserModuleGroup = (marker: Marker, userModuleGroup: UserModuleGroup) => {
        this.userModuleGroupProvider.updateUserModuleGroup(userModuleGroup).then(data => {
            this.updateMarker(marker);
        }, error => {
            // tslint:disable-next-line:no-console
            console.error(error);
            this.setState({networkError: true});
        });
    }

    updateMarker = (marker: Marker) => {
        const { markers } = this.state;
        this.markerProvider.updateMarker(marker).then(data => {
            const { marker } = data;
            const markerIndex = markers.findIndex(e => e._id === marker._id);
            markers[markerIndex] = marker;
            this.setState({
                currentMarker: null,
                markers,
                mainSpinnerText: 'Please wait. Loading data.',
                dataPageIsLoading: false,
                tempTreeNodeId: '',
                tempTreeNodeName: '',
                formHasChanges: false
            });

            if (!marker.jobs) {
                this.setState({
                    wasMarkerUpdated: true
                });
                return;
            }

            const { currentAccount, runningTasks } = this.state;
            const {
                rootStore: { adminStore }
            } = this.props;

            const jobId = Object.keys(marker.jobs)[0];
            const newTask: RunningTasks = {
                account: currentAccount,
                export_name: `Updating marker: ${marker.name}`,
                export_id: marker.marker_id,
                type: 0,
                task_id: jobId,
                status: 102,
                startedAt: Date.now(),
                elapsedTime: 0,
                progress: 0,
                timer: 1500,
                static_progress_count: 0,
                set_to_stop: false
            };
            runningTasks.push(newTask);
            adminStore.setCurrentExportTasks(runningTasks, MainTabs.Marker_Detail);
            this.startTaskWatcher(newTask);
            this.startTimeInterval();
            this.setState({
                runningTasks,
                isJobMenuOpen: true
            })
            return;
        }, error => {
            // tslint:disable-next-line:no-console
            console.error(error);
            this.setState({networkError: true});
        });
    }

    onMarkerUpdatedClick = () => {
        this.setState({wasMarkerUpdated: false});
    }

    onClearMarker = () => {
        this.setState({currentMarker: null});
    }

    onTaskClear = () => {
        const { runningTasks } = this.state;
        const pendingTasks = runningTasks.filter(e => e.status === 102);
        const {
            rootStore: { adminStore }
        } = this.props;
        adminStore.setCurrentExportTasks(pendingTasks, MainTabs.Marker_Detail);
        this.setState({runningTasks: pendingTasks});
    }

    onJobMenuToggle = () => {
        const { isJobMenuOpen } = this.state;
        this.setState({isJobMenuOpen: !isJobMenuOpen});
    }

    getJobCount = (needBoolean: Boolean) => {
        const { runningTasks } = this.state;

        const finishedCount = runningTasks.filter(e => e.status !== 102).length;
        const totalCount = runningTasks.length;

        if (needBoolean) {
            return finishedCount === totalCount;
        }

        return `${finishedCount}/${totalCount}`;
    }

    onNotificationModalClicked = () => {
        this.setState({isNotificationModalOpened: false});   
    }
    
    onTaskClick = (task: RunningTasks) => {
        if (task.status === 102) {
            return;
        }
        if(task.status === 200) {
            return;
        }
        this.jobProvider.checkJobError(task.task_id)
            .then((data) => {
                this.setState({
                    isNotificationModalOpened: true,
                    notificationModalHeader: data.error,
                    notificationModalText: data.stack_trace
                });
            }).catch((error) => {
                // tslint:disable-next-line:no-console
                console.error(error);
                this.setState({networkError: true});
            });
    }

    onModuleGroupsWizardButtonClick = () => {
        const { isModuleGroupWizardOpen } = this.state;

        this.setState({isModuleGroupWizardOpen: !isModuleGroupWizardOpen});
    }

    onMetaFieldsWizardButtonClick = () => {
        const { isMetaFieldsWizardOpen } = this.state;

        this.setState({isMetaFieldsWizardOpen: !isMetaFieldsWizardOpen});
    }

    processJobs = (jobs: RunningTasks[], sourceList: MarkerSourceItem[]) => {
        const { runningTasks, treePickedId, treePickedType, treePickedName } = this.state;
        const {
            rootStore: { adminStore }
        } = this.props;
        this.startTimeInterval();
        jobs.forEach(newTask => {
            runningTasks.push(newTask);
            adminStore.setCurrentExportTasks(runningTasks, MainTabs.Marker_Detail);
            this.startTaskWatcher(newTask);
        });
        this.setState({
            runningTasks,
            isModuleGroupWizardOpen: false,
            isMetaFieldsWizardOpen: false,
        });
        if (sourceList.find(e => e.type === 'CSV')) {
            this.setState({
                currentMarker: null,
                treePickedType: PickedType.None,
                treePickedId: '',
                treePickedName: '',
                markers: [],
                currentMarkerOffset: 0,
                totalMarkers: 0
            });
            return;
        }
        if (sourceList.find(e => e.id === treePickedId)) {
            this.setState({currentMarker: null});
            if (treePickedType === PickedType.FloorPlan) {                
                this.fetchFloorplanMarkers(treePickedId, treePickedName);
            } else {
                this.fetchFolderMarkers(treePickedId, treePickedName);
            }
        }
    }

    onChangesModalClicked = (dialogResult: DialogResult) => {
        if (!dialogResult) {
            return;
        }

        if (dialogResult === DialogResult.Ok) {
            const { tempTreeNodeId, tempTreeNodeName } = this.state;
            if (tempTreeNodeId !== '' && tempTreeNodeName !== '') {
                this.setState({
                    formHasChanges: false,
                    treePickedId: tempTreeNodeId,
                    treePickedName: tempTreeNodeName
                }, () => {
                    this.fetchFloorplanMarkers(tempTreeNodeId, tempTreeNodeName);
                });
            }
        }

        this.setState({isChangesModalOpen: false});
    }

    onFormChanges = () => {
        this.setState({formHasChanges: true});
    }

    public render() {
        const { classes, rootStore } = this.props;
        const {
            accounts,
            currentAccount,
            currentAccountOffset,
            networkError,
            dataPageIsLoading,
            mainSpinnerText,
            isAccountsLoading,
            totalAccounts,
            folders,
            isFoldersLoading,
            currentNodeTree,
            treeIsLoading,
            treePickedType,
            treePickedId,
            treePickedName,
            markers,
            totalMarkers,
            markersIsLoading,
            currentMarkerOffset,
            isMarkerList,
            currentMarker,
            collapseAccounts,
            wasMarkerUpdated,
            moduleGroups,
            runningTasks,
            currentTasksOffset,
            isJobMenuOpen,
            isNotificationModalOpened,
            notificationModalHeader,
            notificationModalText,
            isModuleGroupWizardOpen,
            isMetaFieldsWizardOpen,
            accountModuleGroups,
            isChangesModalOpen,
            userModules,
            accountUserModules
        } = this.state;

        const currentAccountName = currentAccount ? currentAccount.name : '';
        const container = window !== undefined ? () => window.document.body : undefined;

        return (
            <div className={classes.container} data-testid="mainRender">
                <CCSpinner
                    classes={{ darkOverlay: classes.spinnerOverlay }}
                    label={mainSpinnerText}
                    overlayVisible={true}
                    loading={dataPageIsLoading}
                    size={200}
                />
                <AccountFolderMarker
                    className={`${classes.animContainer} ${isMarkerList ? currentMarker ? collapseAccounts ? classes.accountCollapsedSize : classes.accountSmallSize : classes.accountMediumSize : classes.accountFullSize}`}
                    rowsPerPage={ROWS_PER_PAGE}
                    accounts={accounts}
                    accountsIsLoading={isAccountsLoading}
                    accountsTotalItems={totalAccounts}
                    accountsItemsOffset={currentAccountOffset}
                    currentAccountName={currentAccountName}
                    folders={folders}
                    folderIsLoading={isFoldersLoading}
                    onAccountClick={this.onAccountClicked}
                    onAccountDataPageOverBoundary={
                        this.onAccountDataPageOverBoundaryReached
                    }
                    currentNodeTree={currentNodeTree}
                    treeIsLoading={treeIsLoading}
                    onNodeSelected={this.onNodeSelected}
                    onNodeToggled={this.onNodeToggled}
                    pickedType={treePickedType}
                    pickedId={treePickedId}
                    collapseAccounts={collapseAccounts}
                    onCollapseAccountClick={this.onCollapseAccounts}
                    onAccountSearch={this.searchAccounts}
                />
                {
                    isMarkerList && currentAccount &&
                        <FloorplanMarkerDetail
                            className={classes.markerContainerSize}
                            markers={markers}
                            markersIsLoading={markersIsLoading}
                            currentFloorplanName={treePickedName}
                            markersTotalItems={totalMarkers}
                            markersItemsOffset={currentMarkerOffset}
                            rowsPerPage={ROWS_PER_PAGE}
                            moduleGroups={moduleGroups}
                            accountModuleGroups={accountModuleGroups}
                            userModules={userModules}
                            accountUserModules={accountUserModules}
                            hiddenAttributes={MARKER_HIDDEN_ATTRIBUTES}
                            accountId={currentAccount.accountId}
                            onMarkerClick={this.onMarkerClick}
                            onMarkerSaveChanges={this.onMarkerSaveChanges}
                            onCurrentMarkerClear={this.onClearMarker}
                            onFormChanges={this.onFormChanges}
                        />
                }
                {
                    (runningTasks && (runningTasks.length > 0)) &&
                    <React.Fragment>
                        <Button
                            className={classes.jobMenuButton}
                            variant="contained"
                            color="primary"
                            data-testid="marker-detail-job-drawer-button"
                            onClick={this.onJobMenuToggle}
                        >
                            Running Jobs
                            <Chip
                                label={this.getJobCount(false)}
                                className={`${this.getJobCount(true) ? classes.finishedBadge : classes.notFinishedBadge}`}
                            />
                        </Button>
                        <Drawer
                            container={container}
                            variant={"temporary"}
                            anchor={"right"}
                            open={isJobMenuOpen}
                            onClose={this.onJobMenuToggle}
                            classes={{
                                paper: classes.paper,
                            }}
                        >
                            <RunningTasksList
                                className={classes.tasksContainerSize}
                                tasks={runningTasks}
                                rowsPerPage={ROWS_PER_PAGE}
                                taskItemsOffset={currentTasksOffset}
                                listName="Running Exports"
                                onTaskClear={this.onTaskClear}
                                onDrawerClose={this.onJobMenuToggle}
                                onTaskClick={this.onTaskClick}
                            />
                        </Drawer>
                    </React.Fragment>
                }
                <React.Fragment>
                    {
                        /* *
                        Module Group Wizard section
                        * */
                    }
                    <Button
                        className={classes.moduleGroupOperationButton}
                        variant="contained"
                        color="secondary"
                        data-testid="marker-module-group-wizard-button"
                        disabled={!currentAccount || accountModuleGroups.length <= 0 }
                        onClick={this.onModuleGroupsWizardButtonClick}
                    >
                        Module Groups Updates
                    </Button>
                    <SimpleModal
                        className={classes.wizardPopup}
                        open={isModuleGroupWizardOpen}
                        contentClasses={classes.wizardContentContainer}
                        buttonOkLabel=""
                        buttonCancelLabel=""
                        header={
                            <div className={classes.modalHeaderBar}>
                                <Typography variant="h5">
                                    {`Module Groups Operation For ${currentAccountName}`}
                                </Typography>
                                <IconButton
                                    className={classes.closeButton}
                                    onClick={this.onModuleGroupsWizardButtonClick}
                                >
                                    <Close />
                                </IconButton> 
                            </div>
                            }
                        headerClassName={classes.wizardPopupHeader}
                    >
                        <MarkerModuleGroupsWizard
                            className={classes.wizardHolder}
                            currentAccount={currentAccount!}
                            canDelete={true}
                            canAdd={true}
                            currentNodeTree={currentNodeTree}
                            folders={folders}
                            availableModuleGroups={accountModuleGroups}
                            accountProvider={this.accountProvider}
                            folderProvider={this.folderProvider}
                            floorplanProvider={this.floorplanProvider}
                            markerProvider={this.markerProvider}
                            processJobs={this.processJobs}
                        />
                    </SimpleModal>
                    {
                        /* *
                        Meta fields Wizard section
                        * */
                    }
                    <Button
                        className={classes.markerMetaUpdatesButton}
                        variant="contained"
                        color="secondary"
                        data-testid="marker-meta-fields-updates-wizard-button"
                        disabled={!currentAccount}
                        onClick={this.onMetaFieldsWizardButtonClick}
                    >
                        Meta Fields Updates
                    </Button>
                    <SimpleModal
                        className={classes.wizardPopup}
                        open={isMetaFieldsWizardOpen}
                        contentClasses={classes.wizardContentContainer}
                        buttonOkLabel=""
                        buttonCancelLabel=""
                        header={
                            <div className={classes.modalHeaderBar}>
                                <Typography variant="h5">
                                    {`Meta Fields Operations For ${currentAccountName}`}
                                </Typography>
                                <IconButton
                                    className={classes.closeButton}
                                    onClick={this.onMetaFieldsWizardButtonClick}
                                >
                                    <Close />
                                </IconButton> 
                            </div>
                            }
                        headerClassName={classes.wizardPopupHeader}
                    >
                        <MarkerMetaFieldsWizard
                            rootStore={rootStore}
                            className={classes.wizardHolder}
                            currentAccount={currentAccount!}
                            currentNodeTree={currentNodeTree}
                            folders={folders}
                            accountProvider={this.accountProvider}
                            folderProvider={this.folderProvider}
                            floorplanProvider={this.floorplanProvider}
                            jobProvider={this.jobProvider}
                            processJobs={this.processJobs}
                        />
                    </SimpleModal>
                </React.Fragment>
                <SimpleDialog
                    open={isChangesModalOpen}
                    titleText="You have unsaved changes"
                    contentText="Do you wish to discard them?"
                    buttonCancelLabel="No"
                    buttonOkLabel="Yes"
                    onDialogResult={this.onChangesModalClicked}
                />
                <SimpleDialog
                    open={isNotificationModalOpened}
                    titleText={notificationModalHeader}
                    contentText={notificationModalText}
                    buttonCancelLabel=""
                    onDialogResult={this.onNotificationModalClicked}
                />
                {
                    wasMarkerUpdated &&
                    <SimpleDialog
                        open={wasMarkerUpdated}
                        titleText="Success"
                        contentText="Marker updated succesfully"
                        buttonCancelLabel=""
                        onDialogResult={this.onMarkerUpdatedClick}
                    />
                }
                {
                    networkError &&
                        <SimpleModal
                            className={classes.errorPopup}
                            open={networkError}
                            contentClasses={classes.errorPopupContentContainer}
                            buttonOkLabel=""
                            buttonCancelLabel=""
                            header=''
                            headerClassName={classes.errorPopupHeader}
                        >
                            <Error color="error" className={classes.errorPopupIcon} />
                            <div>
                                <Typography variant="h5">
                                    {'Network Error'}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {'Please reload the page.'}
                                </Typography>
                            </div>
                        </SimpleModal>
                }
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(MarkerDetail);
export {MUIComponent as MarkerDetail}