import { Button, Chip, Drawer, Typography } from '@material-ui/core';
import {
    createStyles,
    Theme,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { AddOutlined, Error } from '@material-ui/icons';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { AccountPicker, RunningTasksList } from '../../components';
import {
    AccountForm,
    accountForm
} from '../../components/account-form/account-form';
import { AccountFull } from '../../models/account';
import { FolderFull } from '../../models/folder';
import { ModuleGroup } from '../../models/module-group';
import { Structure } from '../../models/structure';
import AccountProvider from '../../providers/account.provider';
import FolderProvider from '../../providers/folder.provider';
import JobProvider, {
    RunningTasks,
    STATIC_PROGRESS_LIMIT,
    StatusTypes
} from '../../providers/job.provider';
import ModuleGroupProvider from '../../providers/modulegroup.provider';
import StructureProvider from '../../providers/structure.provider';
import { CCSpinner } from '../../shared/components/cc-spinner';
import {
    DialogResult,
    SimpleDialog
} from '../../shared/components/simple-dialog';
import { PageBoundary } from '../../shared/components/simple-grid-pagination';
import { SimpleModal } from '../../shared/components/simple-modal';
import { MainTabs } from '../../stores/admin.store';
import { RootStore } from '../../stores/root.store';
import { JobResultDataset } from '../folders/folders';

const ROWS_PER_PAGE = 10;
const ACCOUNTS_PAGING_LIMIT = 20;
const EDIT_ACCOUNT_FORM: accountForm[] = [
    {
        field: 'name',
        required: true,
        input: 'string'
    },
    {
        field: 'module_groups',
        required: false,
        input: 'modules'
    }
];
const CREATE_ACCOUNT_FORM: accountForm[] = [
    {
        field: 'name',
        required: true,
        input: 'string'
    },
    {
        field: 'email_domain_sso',
        required: true,
        input: 'emails'
    },
];
const EDIT_ACCOUNT_HIDDEN_ATTRIBUTES = [
    '_rev',
    '_sync',
    'structures',
    'jobs',
    'config',
    'type'
];
const CREATE_ACCOUNT_HIDDEN_ATTRIBUTES = [
    '_id',
    '_rev',
    '_sync',
    'structures',
    'jobs',
    'config',
    'type',
    'account_id',
    'timestamp',
    'meta'
];

const styles = (theme: Theme) =>
    createStyles({
        container: {
            flex: 1,
            display: 'flex',
            flexDirection: 'row'
        },
        analyticsTabList: {
            height: '18.2em'
        },
        errorPopup: {
            width: '98%',
            borderRadius: 5
        },
        errorPopupHeader: {
            height: '0.6em',
            backgroundColor: theme.palette.error.main,
            borderRadius: '3px 3px 0 0'
        },
        errorPopupContentContainer: {
            display: 'flex',
            flexDirection: 'row',
            padding: theme.spacing(1)
        },
        errorPopupIcon: {
            fontSize: 40,
            marginRight: '1em'
        },
        mainSpinnerLabel: {
            paddingBottom: '30px'
        },
        progressContainer: {
            flexGrow: 1
        },
        animContainer: {
            transition: 'width 1s'
        },
        listIcon: {
            marginRight: '0.5em',
            fontSize: '1em'
        },
        formHolder: {
            marginLeft: '1em',
            marginTop: '1.5em'
        },
        formContainer: {
            width: '30em'
        },
        jobMenuButton: {
            position: 'fixed',
            right: '6%',
            top: '3%'
        },
        notFinishedBadge: {
            marginLeft: '1em',
            background: '#ffb300',
            color: 'white',
            fontWeight: 'bolder'
        },
        finishedBadge: {
            marginLeft: '1em',
            background: '#107d00',
            color: 'white',
            fontWeight: 'bolder'
        },
        paper: {
            zIndex: 'auto'
        },
        tasksContainerSize: {
            width: '30em',
            padding: '0 1em'
        }
    });

interface Props extends WithStyles<typeof styles> {
    rootStore: RootStore;
    onAuthError?: () => void;
}

interface States {
    accounts: AccountFull[];
    currentAccount: AccountFull | null;
    currentAccountOffset: number;
    networkError: boolean;
    dataPageIsLoading: boolean;
    isAccountsLoading: boolean;
    totalAccounts: number;
    mainSpinnerText: string;
    isCreateAccount: boolean;
    formAccount: AccountFull | undefined;
    formInputs: accountForm[];
    formHiddenAttributes: string[];
    isFormProcessing: boolean;
    formStructure: Structure | undefined;
    runningTasks: RunningTasks[];
    currentTasksOffset: number;
    timeElapser: number | null;
    isJobMenuOpen: boolean;
    isNotificationModalOpened: boolean;
    notificationModalHeader: string;
    notificationModalText: string;
    isCreateIntent: boolean;
    formHasChanges: boolean;
    isChangesModalOpen: boolean;
    moduleGroups: ModuleGroup[];
    searchAccountName: string;
    storedAccounts: AccountFull[];
    storedAccountOffset: number;
    storedAccountTotalCount: number;
}

@inject('rootStore')
@observer
class Accounts extends React.Component<Props, States> {
    public static defaultProps: Partial<Props> = {};

    state: States = {
        accounts: [],
        currentAccount: null,
        currentAccountOffset: 0,
        networkError: false,
        dataPageIsLoading: true,
        isAccountsLoading: false,
        totalAccounts: 0,
        mainSpinnerText: 'Please wait. Loading data.',
        isCreateAccount: false,
        formAccount: undefined,
        formInputs: [],
        formHiddenAttributes: [],
        isFormProcessing: false,
        formStructure: undefined,
        runningTasks: [],
        currentTasksOffset: 0,
        timeElapser: null,
        isJobMenuOpen: false,
        isNotificationModalOpened: false,
        notificationModalHeader: '',
        notificationModalText: '',
        isCreateIntent: false,
        formHasChanges: false,
        isChangesModalOpen: false,
        moduleGroups: [],
        searchAccountName: '',
        storedAccounts: [],
        storedAccountOffset: 0,
        storedAccountTotalCount: 0
    };

    accountProvider = new AccountProvider();
    jobProvider = new JobProvider();
    structureProvider = new StructureProvider();
    moduleGroupProvider = new ModuleGroupProvider();
    folderProvider = new FolderProvider();

    componentDidMount() {
        this.fetchAccounts(this.state.currentAccountOffset);
        this.fetchRunningTasks();
        this.startTimeInterval();
    }

    componentWillUnmount() {
        const {
            rootStore: { adminStore }
        } = this.props;
        const { runningTasks } = this.state;
        this.clearIntervals(runningTasks);
        adminStore.setCurrentExportTasks(runningTasks, MainTabs.Accounts);
        this.clearTimeElapserInterval();
    }

    clearIntervals(tasks: RunningTasks[]) {
        tasks.forEach(element => {
            element.set_to_stop = true;
        });
    }

    clearTimeElapserInterval() {
        const { timeElapser } = this.state;
        if (timeElapser) {
            window.clearInterval(timeElapser);
        }
    }

    startTimeInterval() {
        const { timeElapser } = this.state;
        if (timeElapser) {
            return;
        }
        const timeElapserInterval = window.setInterval(() => {
            const { runningTasks } = this.state;
            const time = Date.now();
            const processing = runningTasks.filter(e => e.status === 102);
            processing.forEach(element => {
                element.elapsedTime = Math.round(
                    (time - element.startedAt) / 1000
                );
            });
            this.setState({ runningTasks });
        }, 1000);
        this.setState({ timeElapser: timeElapserInterval });
    }

    fetchRunningTasks = () => {
        const {
            rootStore: { adminStore }
        } = this.props;
        const tasks = adminStore.currentExportTasks.find(
            e => e.page === MainTabs.Accounts
        );
        this.setState(
            {
                runningTasks: tasks!.tasks
            },
            () => {
                tasks!.tasks.forEach(element => {
                    if (element.status === 102) {
                        // SET TO RESTART POOLING
                        element.set_to_stop = false;
                        this.startTaskWatcher(element);
                    }
                });
            }
        );
    };

    fetchAccounts = (offset: number, limit: number = ACCOUNTS_PAGING_LIMIT) => {
        return this.accountProvider
            .getAllAccountsFullSchema(offset, limit)
            .then(results => {
                const { accounts, total_account } = results;
                if (!accounts) {
                    if (offset === 0) {
                        this.setState({ networkError: true });
                    }
                    return;
                }

                this.setState({
                    isAccountsLoading: false,
                    totalAccounts: total_account,
                    accounts,
                    currentAccountOffset: offset
                }, () => {
                    this.fetchModuleGroups();
                });
            })
            .catch(error => {
                const { status } = error.response;
                if (status === 401) {
                    const { onAuthError } = this.props;
                    if (!onAuthError) {
                        return;
                    }
                    onAuthError();
                    return;
                }
                this.setState({ networkError: true });
            });
    };

    searchAccounts = (searchName: string, offset?: number) => {
        // Nothing to search load the normal account list
        if (searchName === '') {
            const {
                storedAccounts,
                storedAccountOffset,
                storedAccountTotalCount
            } = this.state;

            // Empty search do nothing
            if (!storedAccounts.length) {
                return;
            }

            // Going back from actual seach
            this.setState({
                accounts: storedAccounts,
                currentAccountOffset: storedAccountOffset,
                totalAccounts: storedAccountTotalCount,
                searchAccountName: '',
                storedAccounts: [],
                storedAccountOffset: 0,
                storedAccountTotalCount: 0
            });
            return;
        }

        // We have a name search
        const {
            currentAccountOffset,
            accounts,
            totalAccounts,
            storedAccounts
        } = this.state;
        this.setState({
            isAccountsLoading: true,
            searchAccountName: searchName
        }, () => {
            const searchOffset = offset ? offset : 0;
            return this.accountProvider
                .searchAccountByNameFullSchema(searchName, searchOffset, ACCOUNTS_PAGING_LIMIT)
                .then(results => {
                    const { accounts: foundAccounts, total_account } = results;
                    if (!foundAccounts) {
                        if (currentAccountOffset === 0) {
                            this.setState({ networkError: true });
                        }
                        return;
                    }
    
                    // If we haven't stored the original list do it
                    // This prevents multiple searchs overriding each others
                    if (!storedAccounts.length) {
                        this.setState({
                            storedAccounts: accounts,
                            storedAccountTotalCount: totalAccounts,
                            storedAccountOffset: currentAccountOffset
                        })
                    }

                    this.setState({
                        isAccountsLoading: false,
                        totalAccounts: total_account,
                        accounts: foundAccounts,
                        currentAccountOffset: searchOffset
                    });
                })
                .catch(error => {
                    const { status } = error.response;
                    if (status === 401) {
                        const { onAuthError } = this.props;
                        if (!onAuthError) {
                            return;
                        }
                        onAuthError();
                        return;
                    }
                    this.setState({ networkError: true });
                });
        });
    }

    fetchModuleGroups = () => {
        return this.moduleGroupProvider.fetchModuleGroups()
          .then((results) => {
            const {modules} = results;

            this.setState({
                moduleGroups: modules,
                dataPageIsLoading: false
            });
        }).catch((error) => {
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({networkError: true});
        });
    }

    startTaskWatcher = (task: RunningTasks) => {
        const { runningTasks } = this.state;

        if (!task.export_name || !task.task_id || !task.export_id) {
            return;
        }

        const statusCallback = (taskId: string) => {
            const taskIndex = runningTasks.findIndex(e => e.task_id === taskId);
            const taskItem = runningTasks.find(e => e.task_id === taskId);

            if (!taskItem || taskItem.status !== 102) {
                return;
            }

            if (taskItem.set_to_stop) {
                return;
            }
            
            this.jobProvider.checkJobStatus(taskItem.task_id).then(data => {
                if (data.job_status === StatusTypes.Error) {
                    taskItem.status = 500;
                    runningTasks[taskIndex] = taskItem;
                    this.setState({runningTasks});
                }
                if (data.job_status === StatusTypes.Complete) {
                    taskItem.status = 200;
                    taskItem.progress = 100.0;
                    this.jobProvider.checkJobResult(taskItem.task_id).then(res => {
                        const results = res.result as JobResultDataset;
                        taskItem.result_data = results.result;
                        runningTasks[taskIndex] = taskItem;
                        this.setState({runningTasks});
                    }).catch((error) => {
                        const { status } = error.response;
                        // CHECK IF WE TIMEOUT OR NOT
                        if (status === 408 || status === 504) {
                            // WE HAVE TIMEOUT INCREASE TIMER AND KEEP TRYING
                            taskItem.timer += 1000;
                            setTimeout(taskItem.internalCallback!, taskItem.timer, taskId);
                            return;
                        }
                        taskItem.status = 500;
                        runningTasks[taskIndex] = taskItem;
                        this.setState({runningTasks});
                    });
                } else {
                    // KEEP TRACK OF THE PROGRESS STATUS IF ITS STILL THE SAME
                    if (taskItem.progress === data.estimate_percent_complete) {
                        taskItem.static_progress_count += 1;
                    }
                    taskItem.progress = data.estimate_percent_complete;
                    // IF WE REACH A POINT WHERE THE STATUS IS THE SAME THE JOB IS SLOW
                    // MAKE FEWER REQUESTS TO NOT OVERWHELM THE STATUS ENDPOINT
                    if (taskItem.static_progress_count === STATIC_PROGRESS_LIMIT) {
                        taskItem.timer += 1000;
                        taskItem.static_progress_count = 0;
                    }
                    setTimeout(taskItem.internalCallback!, taskItem.timer, taskId);
                }
            }).catch((error) => {
                const { status } = error.response;
                // CHECK IF WE TIMEOUT OR NOT
                if (status === 408 || status === 504) {
                    // WE HAVE TIMEOUT INCREASE TIMER AND KEEP TRYING
                    taskItem.timer += 1000;
                    setTimeout(taskItem.internalCallback!, taskItem.timer, taskId);
                    return;
                }
                taskItem.status = 500;
                runningTasks[taskIndex] = taskItem;
                this.setState({runningTasks});
            });
        }

        // PREVENT GENERATING TIMERS WITHOUT PROPERTIES BEING INITIALIZED
        const taskIndex = runningTasks.findIndex(e => e.task_id === task.task_id);
        runningTasks[taskIndex].internalCallback = statusCallback;
        this.setState({runningTasks}, () => {
            setTimeout(statusCallback, task.timer, task.task_id);
        });
    };

    onTaskClick = (task: RunningTasks) => {
        if (task.status === 102) {
            return;
        }
        if (task.status === 200) {
            return;
        }
        this.jobProvider
            .checkJobError(task.task_id)
            .then(data => {
                this.setState({
                    isNotificationModalOpened: true,
                    notificationModalHeader: data.error,
                    notificationModalText: data.stack_trace
                });
            })
            .catch(error => {
                // tslint:disable-next-line:no-console
                console.error(error);
                this.setState({ networkError: true });
            });
    };

    onAccountDataPageOverBoundaryReached = (
        boundary: PageBoundary,
        nextPage: number
    ): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const { currentAccountOffset, totalAccounts, searchAccountName } = this.state;
            const isFirstPage = nextPage === 0;
            const isLastPage =
                nextPage === Math.ceil(totalAccounts / ROWS_PER_PAGE) - 1;
            const newOffset = isFirstPage
                ? 0
                : isLastPage
                ? ACCOUNTS_PAGING_LIMIT *
                  Math.floor(totalAccounts / ACCOUNTS_PAGING_LIMIT)
                : boundary === PageBoundary.Upper
                ? currentAccountOffset + ACCOUNTS_PAGING_LIMIT
                : currentAccountOffset - ACCOUNTS_PAGING_LIMIT;
            this.setState({ isAccountsLoading: true });
            if (searchAccountName !== '') {
                this.searchAccounts(searchAccountName, newOffset);
                return;
            }
            this.fetchAccounts(newOffset)
                .then(() => resolve())
                .catch(() => reject());
        });
    };

    onAccountClicked = (account: AccountFull) => {
        const { currentAccount } = this.state;
        if (currentAccount === account) {
            return;
        }

        this.setState({
            currentAccount: account
        });

        const { formAccount } = this.state;

        if (formAccount) {
            // CHECK THAT WE DONT RELOAD THE SAME ACCOUNT
            if (formAccount.account_id === account.account_id) {
                return;
            }

            // CHECK IF WE HAVE UNSAVED CHANGES AND INFORM THE USER
            const { formHasChanges } = this.state;
            if (formHasChanges) {
                this.setState({
                    isChangesModalOpen: true,
                    isCreateIntent: false
                });
                return;
            }
        }

        if (!account.module_groups){
            this.accountProvider.fetchModuleGroupsFromAccountId(account.account_id).then(data => {
                const { module_groups } = data;
                account.module_groups = {};
                module_groups.forEach(element => {
                    account.module_groups![element.module_group_id] = true;
                });

                this.setState({
                    isCreateAccount: false,
                    formAccount: undefined
                });

                setTimeout(() => {
                    this.setState({
                        formAccount: account,
                        formInputs: EDIT_ACCOUNT_FORM,
                        formHiddenAttributes: EDIT_ACCOUNT_HIDDEN_ATTRIBUTES
                    });
                }, 500);
            }).catch((error) => {
                const { status } = error.response;
                if (status === 401) {
                    const { onAuthError } = this.props;
                    if (!onAuthError) {
                        return;
                    }
                    onAuthError();
                    return;
                }
                this.setState({ networkError: true });
            });
            return;
        }

        this.setState({
            isCreateAccount: false,
            formAccount: undefined
        });

        setTimeout(() => {
            this.setState({
                formAccount: account,
                formInputs: EDIT_ACCOUNT_FORM,
                formHiddenAttributes: EDIT_ACCOUNT_HIDDEN_ATTRIBUTES
            });
        }, 500);
    };

    onNotificationModalClicked = () => {
        this.setState({ isNotificationModalOpened: false });
    };

    onJobMenuToggle = () => {
        const { isJobMenuOpen } = this.state;
        this.setState({ isJobMenuOpen: !isJobMenuOpen });
    };

    getJobCount = (needBoolean: Boolean) => {
        const { runningTasks } = this.state;

        const finishedCount = runningTasks.filter(e => e.status !== 102).length;
        const totalCount = runningTasks.length;

        if (needBoolean) {
            return finishedCount === totalCount;
        }

        return `${finishedCount}/${totalCount}`;
    };

    onTaskClear = () => {
        const { runningTasks } = this.state;
        const pendingTasks = runningTasks.filter(e => e.status === 102);
        const {
            rootStore: { adminStore }
        } = this.props;
        adminStore.setCurrentExportTasks(pendingTasks, MainTabs.Accounts);
        this.setState({ runningTasks: pendingTasks });
    };

    onChangesModalClicked = (dialogResult: DialogResult) => {
        if (!dialogResult) {
            return;
        }

        if (dialogResult === DialogResult.Ok) {
            const { currentAccount, isCreateIntent } = this.state;

            this.setState(
                {
                    formHasChanges: false
                },
                () => {
                    if (isCreateIntent) {
                        this.onCreateAccountClick();
                    } else {
                        this.onAccountClicked(currentAccount!);
                    }
                }
            );
        }
        this.setState({ isChangesModalOpen: false });
    };

    onCreateAccountClick = () => {
        // CHECK IF WE HAVE UNSAVED CHANGES AND INFORM THE USER
        const { formHasChanges } = this.state;
        if (formHasChanges) {
            this.setState({ isChangesModalOpen: true, isCreateIntent: true });
            return;
        }

        this.setState({
            isCreateAccount: false,
            formAccount: undefined
        });

        const newAccountModel: AccountFull = {
            _id: '',
            account_id: '',
            config: {},
            meta: {},
            module_group_isolated: true,
            name: '',
            structures: [],
            type: 'account',
            email_domain_sso: []
        };

        setTimeout(() => {
            this.setState({
                isCreateAccount: true,
                formAccount: newAccountModel,
                formInputs: CREATE_ACCOUNT_FORM,
                formHiddenAttributes: CREATE_ACCOUNT_HIDDEN_ATTRIBUTES
            });
        }, 500);
    };

    onFormChanges = () => {
        this.setState({ formHasChanges: true });
    };

    onFormSubmit = (submittedAccount: AccountFull) => {
        const { isCreateAccount, formAccount } = this.state;

        if (!formAccount) {
            return;
        }

        this.setState(
            {
                isChangesModalOpen: false,
                formHasChanges: false
            },
            () => {
                if (isCreateAccount) {
                    this.onCreateSubmit(submittedAccount);
                    return;
                }

                this.onEditSubmit(submittedAccount);
            }
        );
    };

    onCreateSubmit = (submittedAccount: AccountFull) => {
        this.setState(
            {
                isFormProcessing: true
            },
            () => {
                this.createAccountStructure(submittedAccount);
            }
        );
    };

    createAccountStructure = (submittedAccount: AccountFull) => {
        const structureModel: Structure = {
            _id: '',
            structure: {},
            descending: {},
            ascending: {},
            structure_type: 'folder',
            structure_id: '',
            timestamp: '',
            type: 'structure'
        };
        this.structureProvider
            .createStructure(structureModel)
            .then(data => {
                const { structure } = data;
                this.setState({ formStructure: structure });
                this.createAccount(submittedAccount, structure.structure_id);
            })
            .catch(error => {
                const { status } = error.response;
                if (status === 401) {
                    const { onAuthError } = this.props;
                    if (!onAuthError) {
                        return;
                    }
                    onAuthError();
                    return;
                }
                this.setState({
                    isFormProcessing: false,
                    isNotificationModalOpened: true,
                    notificationModalHeader: `Error creating account: ${status}`,
                    notificationModalText: `Couldn't create the required structure, ${error.stack_trace}`
                });
            });
    };

    createAccount = (submittedAccount: AccountFull, structureId: string) => {
        submittedAccount.structures = [structureId];
        const { currentAccountOffset } = this.state;
        this.accountProvider
            .createAccount(submittedAccount)
            .then(data => {
                const { account } = data;
                if (account.jobs) {
                    const {
                        rootStore: { adminStore }
                    } = this.props;
                    const { runningTasks } = this.state;
                    const jobId = Object.keys(account.jobs)[0];
                    const newTask: RunningTasks = {
                        account: {
                            accountId: account.account_id,
                            name: account.name,
                            structures: account.structures,
                            webIdleSessionTimeoutSeconds: 0,
                            timestamp: new Date(account.timestamp!)
                        },
                        export_name: `Creating account: ${account.name}`,
                        export_id: account.account_id,
                        type: 0,
                        task_id: jobId,
                        status: 102,
                        startedAt: Date.now(),
                        elapsedTime: 0,
                        progress: 0,
                        timer: 1500,
                        static_progress_count: 0,
                        set_to_stop: false
                    };
                    runningTasks.push(newTask);
                    adminStore.setCurrentExportTasks(
                        runningTasks,
                        MainTabs.Accounts
                    );
                    this.startTaskWatcher(newTask);
                    this.startTimeInterval();
                    this.setState({ runningTasks });
                }

                this.setState(
                    {
                        isNotificationModalOpened: true,
                        notificationModalHeader: 'Success',
                        notificationModalText: `Account succesfully created, here are the credentials for the user in MySQL:
                            Database: ${account.meta['cleaningmaps']['dbname']}
                            User: ${account.db_user}
                            Password: ${account.db_password}
                            Please store this credentials properly once this prompt is closed you WON'T be able to get them back.
                        `,
                        isFormProcessing: false,
                        isCreateAccount: false
                    },
                    () => {
                        this.createRootFolder(account.name);
                        this.fetchAccounts(currentAccountOffset);
                    }
                );
            })
            .catch(error => {
                const { status } = error.response;
                if (status === 401) {
                    const { onAuthError } = this.props;
                    if (!onAuthError) {
                        return;
                    }
                    onAuthError();
                    return;
                }
                this.setState({
                    isFormProcessing: false,
                    isNotificationModalOpened: true,
                    notificationModalHeader: `Error creating account: ${status}`,
                    notificationModalText: `Structure: ${structureId} was created but account wasn't, ${error.stack_trace}`
                });
            });
    };

    createRootFolder = (accountName: string) => {
        const { formStructure } = this.state;

        if (!formStructure) {
            return;
        }

        const rootFolder = {
            allow_anonymous: false,
            channels: [],
            name: accountName,
            location: {
                coordinates: [
                    0, 0
                ],
                type:'Point'
            },
            email_domains: {},
            user_app_visible: false,
            position: '',
            public_config: {
                agree: true,
                auth: {
                    read: {
                        maintenance: true,
                        thermal: true
                    },
                    write: {
                        maintenance: true,
                        thermal: true
                    }
                },
                comment: true,
                enabled: true,
                marker_visibility: true,
                user_app_public: false,
                user_app_visible: false
            },
            type: 'folder',
            structure_id: formStructure.structure_id,
            folder_id: '',
            _id: ''
        } as FolderFull;

        this.folderProvider.createFolder(rootFolder).then(data => {
            const { folder } = data;
            formStructure.structure[folder.folder_id] = {};
            this.updateAccountStructure(formStructure);
        }).catch(error => {
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({
                isFormProcessing: false,
                isNotificationModalOpened: true,
                notificationModalHeader: `Error creating root folder: ${status}`,
                notificationModalText: `Folder: Root folder was not created, ${error.stack_trace}`
            });
        });
    }

    updateAccountStructure = (structure: Structure) => {
        this.structureProvider.updateStructure(structure.structure_id,structure).then(data => {
            const { structure } = data;
            if (structure.jobs) {
                const {
                    rootStore: { adminStore }
                } = this.props;
                const { runningTasks } = this.state;
                const jobId = Object.keys(structure.jobs)[0];
                const newTask: RunningTasks = {
                    account: null,
                    export_name: `Updating structure: ${structure.structure_id}`,
                    export_id: structure.structure_id,
                    type: 0,
                    task_id: jobId,
                    status: 102,
                    startedAt: Date.now(),
                    elapsedTime: 0,
                    progress: 0,
                    timer: 1500,
                    static_progress_count: 0,
                        set_to_stop: false
                };
                runningTasks.push(newTask);
                adminStore.setCurrentExportTasks(
                    runningTasks,
                    MainTabs.Accounts
                );
                this.startTaskWatcher(newTask);
                this.startTimeInterval();
                this.setState({ runningTasks });
            }

            this.setState({
                formAccount: undefined,
                formStructure: undefined
            })
        }).catch(error => {
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({
                isFormProcessing: false,
                isNotificationModalOpened: true,
                notificationModalHeader: `Error updating structure: ${status}`,
                notificationModalText: `Structure: Root folder wasn't added to the structure correctly, ${error.stack_trace}`
            });
        });
    }

    onEditSubmit = (submittedAccount: AccountFull) => {
        this.setState({
            isFormProcessing: true
        });

        const { accounts } = this.state;
        this.accountProvider
            .updateAccount(submittedAccount)
            .then(data => {
                const { account } = data;
                if (account.jobs) {
                    const {
                        rootStore: { adminStore }
                    } = this.props;
                    const { runningTasks } = this.state;
                    const jobId = Object.keys(account.jobs)[0];
                    const newTask: RunningTasks = {
                        account: {
                            accountId: account.account_id,
                            name: account.name,
                            structures: account.structures,
                            webIdleSessionTimeoutSeconds: 0,
                            timestamp: new Date(account.timestamp!)
                        },
                        export_name: `Updating account: ${account.name}`,
                        export_id: account.account_id,
                        type: 0,
                        task_id: jobId,
                        status: 102,
                        startedAt: Date.now(),
                        elapsedTime: 0,
                        progress: 0,
                        timer: 1500,
                        static_progress_count: 0,
                        set_to_stop: false
                    };
                    runningTasks.push(newTask);
                    adminStore.setCurrentExportTasks(
                        runningTasks,
                        MainTabs.Accounts
                    );
                    this.startTaskWatcher(newTask);
                    this.startTimeInterval();
                    this.setState({ runningTasks });
                }
                const updatedAccount = accounts.findIndex(
                    e => e.account_id === account.account_id
                );

                if (updatedAccount >= 0) {
                    accounts[updatedAccount] = account;
                }

                this.setState({
                    isNotificationModalOpened: true,
                    notificationModalHeader: 'Success',
                    notificationModalText: 'Account succesfully updated',
                    isFormProcessing: false,
                    isCreateAccount: false,
                    formAccount: undefined,
                    accounts
                });
            })
            .catch(error => {
                const { status } = error.response;
                if (status === 401) {
                    const { onAuthError } = this.props;
                    if (!onAuthError) {
                        return;
                    }
                    onAuthError();
                    return;
                }
                this.setState({
                    isFormProcessing: false,
                    isNotificationModalOpened: true,
                    notificationModalHeader: `Error updating account: ${status}`,
                    notificationModalText: error.stack_trace
                });
            });
    };

    public render() {
        const { classes } = this.props;
        const {
            accounts,
            currentAccount,
            currentAccountOffset,
            networkError,
            dataPageIsLoading,
            mainSpinnerText,
            isAccountsLoading,
            totalAccounts,
            isNotificationModalOpened,
            notificationModalHeader,
            notificationModalText,
            isChangesModalOpen,
            formAccount,
            formInputs,
            formHiddenAttributes,
            isFormProcessing,
            isCreateAccount,
            runningTasks,
            isJobMenuOpen,
            currentTasksOffset,
            moduleGroups
        } = this.state;

        const currentAccountName = currentAccount ? currentAccount.name : '';
        const container =
            window !== undefined ? () => window.document.body : undefined;

        return (
            <div className={classes.container} data-testid="mainRender">
                <CCSpinner
                    label={mainSpinnerText}
                    labelClassName={classes.mainSpinnerLabel}
                    className={classes.progressContainer}
                    loading={dataPageIsLoading}
                    size={200}
                >
                    <AccountPicker
                        className={`${classes.animContainer}`}
                        rowsPerPage={ROWS_PER_PAGE}
                        accounts={accounts}
                        accountsIsLoading={isAccountsLoading}
                        accountsTotalItems={totalAccounts}
                        accountsItemsOffset={currentAccountOffset}
                        currentAccountName={currentAccountName}
                        onAccountClick={this.onAccountClicked}
                        onAccountDataPageOverBoundary={
                            this.onAccountDataPageOverBoundaryReached
                        }
                        onAccountSearch={this.searchAccounts}
                    />
                    <div className={classes.formHolder}>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<AddOutlined />}
                            onClick={this.onCreateAccountClick}
                        >
                            Create new Account
                        </Button>
                        {formAccount && (
                            <AccountForm
                                className={classes.formContainer}
                                accountModuleGroups={moduleGroups}
                                pickedAccount={formAccount}
                                formFields={formInputs}
                                hiddenAttributes={formHiddenAttributes}
                                isProcessing={isFormProcessing}
                                isCreating={isCreateAccount}
                                onFormChanges={this.onFormChanges}
                                onFormSubmit={this.onFormSubmit}
                            />
                        )}
                    </div>
                </CCSpinner>
                {runningTasks && runningTasks.length > 0 && (
                    <React.Fragment>
                        <Button
                            className={classes.jobMenuButton}
                            variant="contained"
                            color="primary"
                            data-testid="marker-detail-job-drawer-button"
                            onClick={this.onJobMenuToggle}
                        >
                            Running Jobs
                            <Chip
                                label={this.getJobCount(false)}
                                className={`${
                                    this.getJobCount(true)
                                        ? classes.finishedBadge
                                        : classes.notFinishedBadge
                                }`}
                            />
                        </Button>
                        <Drawer
                            container={container}
                            variant={'temporary'}
                            anchor={'right'}
                            open={isJobMenuOpen}
                            onClose={this.onJobMenuToggle}
                            classes={{
                                paper: classes.paper
                            }}
                        >
                            <RunningTasksList
                                className={classes.tasksContainerSize}
                                tasks={runningTasks}
                                rowsPerPage={ROWS_PER_PAGE}
                                taskItemsOffset={currentTasksOffset}
                                listName="Running Operations"
                                onTaskClear={this.onTaskClear}
                                onDrawerClose={this.onJobMenuToggle}
                            />
                        </Drawer>
                    </React.Fragment>
                )}
                <SimpleDialog
                    open={isNotificationModalOpened}
                    titleText={notificationModalHeader}
                    contentText={notificationModalText}
                    buttonCancelLabel=""
                    onDialogResult={this.onNotificationModalClicked}
                    testId="credentials-modal"
                />
                <SimpleDialog
                    open={isChangesModalOpen}
                    titleText="You have unsaved changes"
                    contentText="Do you wish to discard them?"
                    buttonCancelLabel="No"
                    buttonOkLabel="Yes"
                    onDialogResult={this.onChangesModalClicked}
                />
                {networkError && (
                    <SimpleModal
                        className={classes.errorPopup}
                        open={networkError}
                        contentClasses={classes.errorPopupContentContainer}
                        buttonOkLabel=""
                        buttonCancelLabel=""
                        header=""
                        headerClassName={classes.errorPopupHeader}
                    >
                        <Error
                            color="error"
                            className={classes.errorPopupIcon}
                        />
                        <div>
                            <Typography variant="h5">
                                {'Network Error'}
                            </Typography>
                            <Typography variant="subtitle1">
                                {'Please reload the page.'}
                            </Typography>
                        </div>
                    </SimpleModal>
                )}
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(Accounts);
export { MUIComponent as Accounts };
